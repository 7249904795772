/* eslint-disable */
import axios from "axios";
import { TOKEN, GET_CUSTOM_INFO, GET_BULLION_RATE, GET_DETAILS, GET_AMOUNT,GET_LOCKER_VALUE,GET_ORDER_ID, SAVE_BANK_DETAILS,SAVE_AGENT_DETAILS,SAVE_CUSTOMER_SIP,SAVE_JEWELLERY_DETAILS,SAVE_PRODUCT_ORDER,PRODUCT_ID} from "./../type";
import { API_ENDPOINT } from "./../../apiConstant";
import {API_ENDPOINT_IFSC} from "./../../apiConstant";

export const setToken = (token) => {
  return dispatch => {
    dispatch({
      type: TOKEN,
      payload: token
    })
  }
}
export const saveProductID = (id) => {
  return dispatch => {
    dispatch({
      type: PRODUCT_ID,
      payload: id
    })
  }
} 
 
export const getCustom = (dispatch, data) => {
  dispatch({
    type: GET_CUSTOM_INFO,
    payload: data
  })
}

export const saveDetails = (dispatch, data) => {
  dispatch({
        type: GET_DETAILS,
        payload: data,
  })
}

export const saveBankDetails = (dispatch, data) => {
  dispatch({
        type: SAVE_BANK_DETAILS,
        payload: data,
  })
}

export const saveAmount = (data) => {
 
  return dispatch => 
  {
      dispatch({
      type: GET_AMOUNT,
      payload: data
    });
  
}
}

export const saveAgentDetails = (dispatch, data) => {
  dispatch({
    type: SAVE_AGENT_DETAILS,
    payload: data
  })
}
export const saveCustomerSIP = (dispatch, data) => {
  dispatch({
    type: SAVE_CUSTOMER_SIP,
    payload: data
  })
}
export const saveProductDetails = (data) => {
  return dispatch => 
  {
  dispatch({
    type: SAVE_JEWELLERY_DETAILS,
    payload: data
  })
}
}

export const saveProductOrder = (dispatch,data) => {
  dispatch({
    type: SAVE_PRODUCT_ORDER,
    payload: data
  })

}


export const buyBullionOrderId = (data) => {
  return dispatch => 
  {
      dispatch({
      type: GET_ORDER_ID,
      payload: data
    });
  
}
}


export const saveBullionRate = (dispatch, data) => {
  dispatch({
        type: GET_BULLION_RATE,
        payload: data,
  })
}

export const saveLockerValue = (dispatch, data) => {
  dispatch({
        type: GET_LOCKER_VALUE,
        payload: data,
  })
}


export const getCustomInfo = ({token}) => {
  let data = {
    token: token
  }
  let url = `${API_ENDPOINT}/api/verify-customer`
  let config = {
    headers: {
      'Content-Type': "application/json"
    }
  }
  return (dispatch) => new Promise(async (resolve, reject) => {
    axios.post(url,data,config)
    .then((response) => {
      getCustom(dispatch, response)
      return resolve(response)
    }).catch((err) => {
      return reject(err)
    });
  })
}

export const getBullionRate = (data1) => {
 
  let url = `${API_ENDPOINT}/api/get-bullion-rate`;
  let config = {
    headers: {
      'Content-Type': "application/json"
    }
  }
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      axios
        .post(url,data1,config)
        .then((res) => {
          saveBullionRate(dispatch, res.data)
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getPassbook = (data1) => {
 
  let url = `${API_ENDPOINT}/api/get-passbook`;
  let config = {
    headers: {
      'Content-Type': "application/json"
    }
  }
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url,data1,config)
        .then((res) => {
          saveLockerValue(dispatch, res.data)
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const postPersonalDetails = ( ReqData) => {
  
  let url = `${API_ENDPOINT}/api/create-customer/`
   let config = {
       headers: {
         'Content-Type': "application/json"
       }
     }
   return dispatch => new Promise((resolve, reject) => {
       axios.post(url,ReqData,config)
       .then((res) => {
        saveDetails(dispatch, res)
           return resolve(res)  
       })
       
       .catch((err) => {
           return reject(err)
       });
   }); 
 };

export const getCustomerDetails = (data) => {
  let url = `${API_ENDPOINT}/api/get-customer/`;
  return (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .post(url,data)
      .then((res) => {
        return resolve(res);
      })

      .catch((err) => {
        return reject(err);
      });
  });
};


export const buyBullion = (order) => {
  
  let url = `${API_ENDPOINT}/api/buy-bullion`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, order,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((error) => {
        // console.log(err.response.status,"PPPPPPPPPPPPPPPPPPPPPPPP");
        //   return reject(err)
        return reject (`${error.response.status}`);
      })
  }) 
}

export const configGateway = (obj) => {

  let url = `${API_ENDPOINT}/api/config-gateway`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, obj,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const intiatePayment = (paymentData) => {
  
  let url = `${API_ENDPOINT}/api/intiate-payment`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, paymentData,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const finalizePayment = (data) => {    
  let url = `${API_ENDPOINT}/api/finalize-payment`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, data,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

 
export const cstmrBankDetails = (bankRequestData) => {
 
  let url = `${API_ENDPOINT}/api/cstmrbankdetails`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, bankRequestData,config)
      .then((res) => {
        saveBankDetails(dispatch, bankRequestData)
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}
export const sellBullion = (requestData) => {
 
  let url = `${API_ENDPOINT}/api/sell-bullion`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, requestData,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const orderInvoice = (data1) => {
 
  let url = `${API_ENDPOINT}/api/order-invoice`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, data1,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const generateOtp = (mobno) => {
 
  let url = `${API_ENDPOINT}/api/generateOTP`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, mobno,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const verifyOtp = (verifyOtp) => {
 
  let url = `${API_ENDPOINT}/api/verifyOTP`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, verifyOtp, config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const consentApi = (consentData) => {
 
  let url = `${API_ENDPOINT}/api/consent`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, consentData, config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const getCustomer = (reqData) => {
 
  let url = `${API_ENDPOINT}/api/get-customer`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, reqData,config)
      .then((res) => {
        saveBankDetails(dispatch, res.data.result)
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}


export const getConsent = (reqData) => {
 
  let url = `${API_ENDPOINT}/api/get-consent`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url,reqData,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}


export const ifscCodeApi = (ifsc,token) => {
  // let url = `https://api.paymeindia.in/api/bankdetails_list/`
  let url = `${API_ENDPOINT_IFSC }/api/bankdetails_list/`
  let config = {
    headers: {
      Authorization: "Token " + token,
    },
  };
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url,ifsc,config)
      .then((res) => {
          return resolve(res.data)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}


export const getAgentDetails = (reqObj) => {
  // let url = `https://staging.api.gold.paymeindia.in/api/get-agent`
  let url = `${API_ENDPOINT}/api/get-agent`
 
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url,reqObj)
      .then((res) => {
        console.log("get-agent",res);
        saveAgentDetails(dispatch, res.data)
          return resolve(res.data)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const cancelOrder = (data) => {    
 
  let url = `${API_ENDPOINT}/api/cancel-order`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url, data,config,{
        responseType: "blob"
      })
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const getCustomerSips = (reqData) => {
 
  let url = `${API_ENDPOINT}/api/get-customer-SIPs`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,reqData,config)
      .then((res) => {
        saveCustomerSIP(dispatch, res.data)
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}
export const getSipsFaqs = (data1) => {
 
  let url = `${API_ENDPOINT}/api/get-SipFAQs`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
    }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url,data1,config)
      .then((res) => {
          return resolve(res)
      })
      .catch((err) => {
          return reject(err)
      })
  }) 
}

export const getSavingPlan = (data1) => {

  let url = `${API_ENDPOINT}/api/get-saving-objective`
  let config = {
      headers: {
          'Content-Type': "application/json"
      }
  }
  return dispatch => new Promise((resolve, reject) => {
      axios.post(url,data1,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const createSIP = (reqData) => {
   
    let url = `${API_ENDPOINT}/api/create-SIP`
    let config = {
        headers: {
            'Content-Type': "application/json"
        }
    }
    return (dispatch) => new Promise((resolve, reject) => {
        axios.post(url,reqData,config)
            .then((res) => {
                saveCustomerSIP(dispatch, res.data)
                return resolve(res)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

export const updateSIP = (reqData) => {
  
    let url = `${API_ENDPOINT}/api/update-SIP`
    let config = {
        headers: {
            'Content-Type': "application/json"
        }
    }
    return (dispatch) => new Promise((resolve, reject) => {
        axios.put(url,reqData,config)
            .then((res) => {
                saveCustomerSIP(dispatch, res.data)
                return resolve(res)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

export const sipOrder = (reqData) => {
 
  let url = `${API_ENDPOINT}/api/sip-order`
  let config = {
      headers: {
          'Content-Type': "application/json"
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,reqData,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const getProductList = (data, token) => {

    let url = `${API_ENDPOINT}/api/products-list`
    let config = {
        headers: {
            Authorization: "Token " + token,
        }
    }
    return (dispatch) => new Promise((resolve, reject) => {
        axios.post(url,data, config)
            .then((res) => {
                return resolve(res)
            })
            .catch((err) => {
                return reject(err)
            })
    })
}

export const getDeliveryAddress = (data) => {
  
  let url = `${API_ENDPOINT}/api/get-delivery-address`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}


export const updateDeliveryAddress = (data) => {
  
  let url = `${API_ENDPOINT}/api/update-delivery-address`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const addDeliveryAddress = (reqData) => {
  
  let url = `${API_ENDPOINT}/api/add-delivery-address`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,reqData,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const deleteDeliveryAddress = (data) => {
  
  let url = `${API_ENDPOINT}/api/delete-delivery-address`
  let config = {
      headers: {
        'Content-Type': "application/json"
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data,config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}


export const getProductDetails = (data, token) => {

  let url = `${API_ENDPOINT}/api/product-detail`
  let config = {
      headers: {
          Authorization: "Token " + token,
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data, config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const addRemoveToCart = (data, token) => {

  let url = `${API_ENDPOINT}/api/add-remove-from-cart`
  let config = {
      headers: {
          Authorization: "Token " + token,
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data, config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const getCartItem = (data, token) => {

  let url = `${API_ENDPOINT}/api/get-cart`
  let config = {
      headers: {
          Authorization: "Token " + token,
      }
  }
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,data, config)
          .then((res) => {
              return resolve(res)
          })
          .catch((err) => {
              return reject(err)
          })
  })
}

export const postProductOrder = (requestObject) => {

  let url = `${API_ENDPOINT}/api/order-products`
  let config = {
    headers: {
      'Content-Type': "application/json"
    }
}
  return (dispatch) => new Promise((resolve, reject) => {
      axios.post(url,requestObject, config)
          .then((res) => {
            saveProductOrder(dispatch,res.data);
              return resolve(res)
          })
          .catch((err) => {
            console.log("product order error",err);
              return reject(err)
          })
  })
}
