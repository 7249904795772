import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import search from "../../images/jewellery/search-icon.svg";
import productItem from "../../images/jewellery/chain.svg";
import filter from "../../images/jewellery/filter-icon.svg";
import cart from "../../images/jewellery/cart.svg";
import { getProductList } from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const YourCart = (props) => {
  const [loader, setLoader] = useState(true);
  const [resData, setResData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      bullionName: "All",
      category: "",
    };

    props
      .getProductList(reqData)
      .then(async (res) => {
        setResData(res.data.result.otheritems);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createSIPSubmit = () => {
    setLoader(true);
  };
  console.log("data", resData);

  return (
    <div style={{ paddingBottom: 120 }}>
      <div className="p-t-40">
            <div className="iconSize">
              <Icon
                size={24}
                icon={angleLeft}
                onClick={() => props.history.goBack("/")}
              />
            </div>
            <div className="page-name">Your Cart<div className='cart'>2<img src={cart} alt='Cart' /></div></div>
       </div>

      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div>
          <Form className=" p-t-20">

            <div className="card-jewellery-box">
            {resData.map((item) => {
              return (
                  <div className="card-cart-wrap"
                       onClick={() =>
                           props.history.push({
                               pathname: "/product-details",
                               state: {jewellaryDetails: item},
                           })
                       }
                  >
                      <div className='img-left'>
                          {item.images[0] ?
                              <img
                                  src={item.images[0].url}
                                  alt="Gold Chain"
                                  className='img-fluid'
                              /> : null}
                      </div>
                      <div className='float-right-link'>Show Details</div>
                      <div className='float-left-section'>
                          <h5 className="price-item">
                              {item.productname}
                          </h5>
                          <p>
                              5 gms
                          </p>
                          <p>
                              22 kt.
                          </p>
                          <h6>Rs. 20,000* <strike>Rs. 12,000*</strike></h6>
                      </div>
                      <div className='btn-yellow'>
                          <div className='btn-round'><a href='#'>-</a>
                              <a href='#'>2</a>
                              <a href='#'>+</a></div>
                      </div>
                  </div>
              );
            })}
            </div>
          </Form>
          <div className="fixedFooter" style={{ padding: 25 }}>
            <div className="filter-icon">
              <img src={filter} alt="Filter" />
            </div>
            <button
              className="savingPlanCSS btn-yellow-large"
              onClick={createSIPSubmit}
            >
                Buy Now
            </button>
          </div>
        </div>
       
      )}
        <div className="card-cart-wrap">
            <div className='float-right-link'>Edit</div>
            <div className='float-left-section'>
                <h5 className="price-item">
                    Delivery Address
                </h5>
                <p>
                    406 - A wing
                    <br/>
                    Street
                <br/>
                City/Town
            <br/>
                Area
          <br/>
                State
            </p> <p>
                Mobile Number
            </p>
            </div>
            <div className='btn-yellow'>
                <p className='m-b-0'>
                    <br/> <br/>
                    (Based on your address)
                    <br/>
                Delievery Charges: Rs. 40
                    <br/>
                Delievery in 5-7 working days
                </p>
            </div>
        </div>

        <div className="card-cart-wrap-blank">
            <ul>
                <li>
                    <div className='first-left'>Price</div>
                    <div className='second-left'>Rs. 45,306</div>
                </li>
                <li>
                    <div className='first-left'>Discount</div>
                    <div className='second-left'>Rs. 60</div>
                </li>
                <li>
                    <div className='first-left'>GST</div>
                    <div className='second-left'>Rs. 1306</div>
                </li>
                <li>
                    <div className='first-left'>Making Charges</div>
                    <div className='second-left'>Rs. 60</div>
                </li>
                <li>
                    <div className='first-left'>GST</div>
                    <div className='second-left'>Rs. 60</div>
                </li>
                <li>
                    <div className='first-left'>Delivery Charges</div>
                    <div className='second-left'>Rs. 60</div>
                </li>
                <li className='seprator'>
                    <div className='first-left'>GST</div>
                    <div className='second-left'>Rs. 60</div>
                </li>

                <li className='total'>
                    <div className='first-left'>Total</div>
                    <div className='second-left'>Rs. 44,040</div>
                </li>
                <li className='seprator'>
                    <div className='first-left'>Debited from Locker</div>
                    <div className='second-left'>Rs. 22,000</div>
                </li>
                <li className='total'>
                    <div className='first-left'>Balance Order Value</div>
                    <div className='second-left'>Rs. 22,040</div>
                </li>
            </ul>

        </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { getProductList })(YourCart);
