import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import search from "../../images/jewellery/search-icon.svg";
import productItem from "../../images/jewellery/chain.svg";
import filter from "../../images/jewellery/filter-icon.svg";
import cart from "../../images/jewellery/cart.svg";
import {
  getCartItem,
  addRemoveToCart,
  getDeliveryAddress,
  updateDeliveryAddress,
  addDeliveryAddress,
  deleteDeliveryAddress,
  configGateway,
  intiatePayment,
  finalizePayment,
  cancelOrder,
  postProductOrder,
  buyBullionOrderId,
  getPassbook,
} from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const options = {
  useWebWorker: true,
  position: "top-center",
  autoClose: 4000,
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const Cart = (props) => {
 
  const [loader, setLoader] = useState(true);
  const [resData, setResData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(props.goldLoan.userData.data);
  const [isdisable, setIsdisable] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [editBtn, setEdit] = useState("");
  const [indexValue, setIndexValue] = useState("");
  const [addFlagStatus, setAddFlagStatus] = useState(false);
  const [inputField, setInputField] = useState({
    houseNumber: "",
    streetName: "",
    district: "",
    state: "",
    country: "",
    pinCode: "",
    mobile: "",
  });
  const [deliveryAdd, setDeliveryAdd] = useState({});
  const [editFlagValue, setEditFlagValue] = useState(false);
  const [addressSelected, setAddressSelected] = useState(true);
  useEffect(() => {
    handleGetItem();
    getAddress();
  }, []);
  const handleGetItem = () => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      bullionName: "All",
      category: "",
    };

    props
      .getCartItem(reqData)
      .then(async (res) => {
        setResData(res.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const RemoveFromCart = (dataitem) => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let payload = {
      jwttoken: data.jwttoken,
      inventoryserialnumber: dataitem.inventoryserialnumber,
      productid: dataitem.productid,

      imageurl: dataitem.imageurl,
      productname: dataitem.productname,
      weightingm: dataitem.weightingm,
      totalwithtaxinr: dataitem.totalwithtaxinr,
      purity: dataitem.purity,
      quantity: 0,
    };
    if (isdisable) {
      props
        .addRemoveToCart(payload)
        .then(async (res) => {
          if (res.status === 200) {
            handleGetItem();
            setIsdisable(false);
            toast.success("Product Removed Successfully", { ...options });
            setTimeout(() => setIsdisable(true), 5000);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getAddress = () => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    props
      .getDeliveryAddress({
        jwttoken: data.jwttoken,
      })
      .then(async (res) => {
        console.log(res.data.result[0].deliveryaddress);
        setDeliveryAddress(res.data.result[0].deliveryaddress);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editAddress = (data, index) => {
    setEditFlagValue(true);
    setAddFlagStatus(false);
    setFlag(false);
    setEdit(data);
    setIndexValue(index);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const EditDeliveryAddress = (e) => {
    const { name, value } = e.target;

    setDeliveryAddress((prevState) => {
      const temp = [...prevState];

      temp[indexValue] = {
        ...prevState[indexValue],
        address: {
          ...prevState[indexValue].address,
          [name]: e.target.value,
        },
        phone: {
          ...prevState[indexValue].phone,
          [name]: e.target.value,
        },
      };

      return temp;
    });
  };

  const addAddress = (data) => {
    setAddFlagStatus(true);
    setFlag(false);
    setEditFlagValue(false);
    setEdit(data);
  };

  const deleteAddress = (id) => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      deliveryadd: {
        _id: id.toString(),
      },
    };
    props
      .deleteDeliveryAddress(reqData)
      .then(async (res) => {
        setLoader(false);
        setFlag(true);
        setAddFlagStatus(false);
        setEditFlagValue(false);
        getAddress();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAddress = (editBtn, index) => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    setLoader(true);
    if (editBtn === "edit") {
      let id = "";

      deliveryAddress.map((item, i) => {
        if (i === index) {
          id = item._id;
        }
      });

      let reqData = {
        jwttoken: data.jwttoken,
        deliveryadd: {
          address: {
            houseNumber: deliveryAddress[indexValue].address.houseNumber,
            streetName: deliveryAddress[indexValue].address.streetName,
            district: deliveryAddress[indexValue].address.district,
            pinCode: deliveryAddress[indexValue].address.pinCode,
            state: deliveryAddress[indexValue].address.state,
            country: deliveryAddress[indexValue].address.country,
          },
          phone: {
            mobile: deliveryAddress[indexValue].phone.mobile,
          },
          _id: id.toString(),
        },
      };
      props
        .updateDeliveryAddress(reqData)
        .then(async (res) => {
          if (res.data.response.msg === "Success") {
            setLoader(false);
            setFlag(true);
            setEditFlagValue(false);
            setAddFlagStatus(false);
            getAddress();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const saveAddress = (editBtn) => {
    if (inputField.mobile.length !== 10) {
      toast.error("Please add correct mobile number.", { ...options });
    } else {
      setLoader(true);
      let { data } = props.goldLoan ? props.goldLoan.userData : "";

      let reqData = {
        jwttoken: data.jwttoken,
        deliveryadd: {
          address: {
            houseNumber: inputField.houseNumber,
            streetName: inputField.streetName,
            district: inputField.district,
            pinCode: inputField.pinCode,
            state: inputField.state,
            country: inputField.country,
          },
          phone: {
            mobile: inputField.mobile,
          },
        },
      };

      props
        .addDeliveryAddress(reqData)
        .then(async (res) => {
          setLoader(false);
          setAddFlagStatus(false);
          setFlag(true);
          setEditFlagValue(false);
          getAddress();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log("editFlagValue", deliveryAddress);
  if (deliveryAddress) {
  }
  const setDeliveryAddressMethod = (delivery) => {
    setDeliveryAdd(delivery);
  };

  const check = (dataValue) => {
    let requsetData = {
      jwttoken: dataValue.razorPayData.jwttoken,
      gateway: {
        gateway: "razorpay",
        config: { key_id: dataValue.razorPayData.key_id },
      },
      txnreference: dataValue.razorPayData.txnreference,
      orderId: dataValue.razorPayData.orderId,
      total: dataValue.razorPayData.totalAmount,
      ordernumber: dataValue.razorPayData.orderNumber,
      gateway_response: {
        checkout_logo: dataValue.responseData.checkout_logo,
        custom_branding: dataValue.responseData.custom_branding,
        org_logo: dataValue.responseData.org_logo,
        org_name: dataValue.responseData.org_name,
        razorpay_order_id: dataValue.responseData.razorpay_order_id,
        razorpay_payment_id: dataValue.responseData.razorpay_payment_id,
        razorpay_signature: dataValue.responseData.razorpay_signature,
      },
    };
    setLoader(true);
    props
      .finalizePayment(requsetData)
      .then((res) => {
        setLoader(false);
        props.history.push({
          pathname: "/jewellery-order-status",
          state: { result: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayRazorpay = (razorPayData) => {
    let cancleOrderData = {
      jwttoken: razorPayData.jwttoken,
      order_uuid: razorPayData.order_uuid,
      reason: "Payment processing cancelled by user",
      ordernumber: razorPayData.orderNumber,
    };

    const options = {
      key: razorPayData.key_id,
      amount: razorPayData.totalAmount,
      currency: "INR",
      name: userDetails.name.first + "  " + userDetails.name.last,
      description: "Gold Investement",
      // image: { logo },
      order_id: razorPayData.txnreference,
      handler: function (response) {
        let dataValue = {
          razorPayData: razorPayData,
          responseData: response,
        };
        check(dataValue);
      },
      prefill: {
        name: userDetails.name.first + "  " + userDetails.name.last,
        email: userDetails.email,
        contact: userDetails.mobile,
      },
      notes: {
        address: "Payme India",
      },
      theme: {
        color: "#61dafb",
      },
      modal: {
        escape: false,
        ondismiss: function () {
          props
            .cancelOrder(cancleOrderData)
            .then((res) => {
              console.log("cancel order response", res);
            })
            .catch((err) => {
              console.log(err);
            });
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      let cancleOrder = {
        jwttoken: razorPayData.jwttoken,
        order_uuid: razorPayData.order_uuid,
        reason: response.error.reason,
        ordernumber: razorPayData.orderNumber,
      };
      props
        .cancelOrder(cancleOrder)
        .then((res) => {
          paymentObject.close();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
console.log("props",props)

  const productOrder = () => {
    if (addressSelected) {
      toast.error("Choose delivery address.", { ...options });
      return;
    }
    if(resData.length === 0){
      toast.error("Choose product.", { ...options });
      return;
    }
    if (deliveryAdd === "" || deliveryAdd === null) {
      setLoader(false);
      toast.error("Choose delivery address.", { ...options });
      return;
    } else {
      setLoader(true);
      let { data } = props.goldLoan ? props.goldLoan.userData : "";
      let agentData = props.goldLoan ? props.goldLoan.agentData : "";
      let requestObject = {
        jwttoken: data.jwttoken,
        agent: {
          extAgentId: agentData.result.extAgentId,
          name: {
            first: agentData.result.name.first,
            middle: agentData.result.name.middle,
            last: agentData.result.name.last,
          },
        },
        productItems:   resData.map((item) => {
          return(
            {
              productid: item.productid,
              inventoryserialnumber: item.inventoryserialnumber,
            }
          )
          
        }) ,
        // maxBullionWtGm: props.goldLoan.jewellery_details.inventoryDetail.convertedBullionWt.totalInBullionWt,
        maxBullionWtGm: 0,
        address: {
          houseNumber: deliveryAdd ? deliveryAdd.address.houseNumber : "",
          streetName: deliveryAdd ? deliveryAdd.address.streetName : "",
          district: deliveryAdd ? deliveryAdd.address.district : "",
          pinCode: deliveryAdd ? deliveryAdd.address.pinCode : "",
          state: deliveryAdd ? deliveryAdd.address.state : "",
          country: deliveryAdd ? deliveryAdd.address.country : "",
        },
      };
   
      props
        .postProductOrder(requestObject)
        .then(async (res) => {
          if (res.data.response.msg === "Success") {
            setLoader(true);
            let orderId = "",
              netAmount = "",
              gatewayType = "",
              keyId = "";

            let buy_bullion_orderNumber = res.data.result.ordernumber;
            let buy_bullion_order_uuid = res.data.result.id;
            netAmount = res.data.result.orderTotalValueInr;
            // netAmount = balanceOrderValue;
            orderId = res.data.result.id;
            props.buyBullionOrderId({
              order_uuid: res.data.result.id,
              ordernumber: res.data.result.ordernumber,
            });

            let obj = {
              txnType: "Buy",
              jwttoken: data.jwttoken,
              orderId: res.data.result.id,
            };
            props.configGateway(obj).then((response) => {
              if (response) {
                gatewayType = response.data.result.gateway;
                keyId = response.data.result.config.key_id;
                let paymentData = {
                  jwttoken: data.jwttoken,
                  gateway: {
                    gateway: gatewayType,
                    config: {
                      key_id: keyId,
                    },
                  },
                  orderId: orderId,
                  total: netAmount,
                };
                props
                  .intiatePayment(paymentData)
                  .then((resp) => {
                    if (resp) {
                      setLoader(false);
                      let razorPayData = {
                        key_id: keyId,
                        orderId: resp.data.result.notes.orderId,
                        txnreference: resp.data.result.id,
                        totalAmount: netAmount,
                        jwttoken: data.jwttoken,
                        orderNumber: buy_bullion_orderNumber,
                        order_uuid: buy_bullion_order_uuid,
                      };
                      displayRazorpay(razorPayData);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [error, setError] = useState("");
  const handlePinCode = (value) => {
    if (value.length === 6) {
      setError("");
      axios
        .get(`https://api.postalpincode.in/Pincode/${value}`)
        .then((res) => {
          console.log("pin", res);
          if (editFlagValue) {
            setDeliveryAddress((prevState) => {
              const temp = [...prevState];

              temp[indexValue] = {
                ...prevState[indexValue],
                address: {
                  ...prevState[indexValue].address,
                  country: res.data[0].PostOffice[0].Country,
                  state: res.data[0].PostOffice[0].State,
                  district: res.data[0].PostOffice[0].District,
                },
              };

              return temp;
            });
          } else {
            setInputField((prevState) => ({
              ...prevState,

              country: res.data[0].PostOffice[0].Country,
              state: res.data[0].PostOffice[0].State,
              district: res.data[0].PostOffice[0].District,
            }));
          }
        })

        .catch((err) => {
          setError("Invalid PIN Code");
        });
    }
    if (value.length !== 6) {
      setError("ZIP code must be of 6 digits");
    }
  };

  return (
    <div style={{ paddingBottom: 120 }}>
      <div className="p-t-40">
        <div className="iconSize">
          <Icon
            size={24}
            icon={angleLeft}
            onClick={() => props.history.goBack("/")}
          />
        </div>
        <div className="page-name">Your Cart </div>
        {/* <div className='cart'>5<img src={cart} alt='Cart' /></div> */}
      </div>

      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div>
          <Form className=" p-t-20">
            <div className="card-jewellery-box">
              {resData.map((item) => {
                return (
                  <div
                    className="card-cart-wrap"
                    //  onClick={() =>
                    //      props.history.push({
                    //          pathname: "/product-details",
                    //          state: {jewellaryDetails: item},
                    //      })
                    //  }
                  >
                    <div className="img-left">
                      {item.imageurl ? (
                        <img
                          src={item.imageurl}
                          alt="Gold Chain"
                          className="img-fluid"
                        />
                      ) : null}
                    </div>

                    <div className="float-left-section">
                      <h5 className="price-item">{item.productname}</h5>
                      <p>{item.weightingm}</p>
                      <p>{item.purity}</p>
                      <h6>Rs{parseInt(item.totalwithtaxinr)}</h6>
                    </div>
                    {/* <div
                      style={{
                        float: "left",
                        marginTop: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Show Details
                    </div> */}
                    <div className="btn-yellow">
                      <div
                        onClick={() => {
                          RemoveFromCart(item);
                        }}
                        className="btn-round"
                      >
                        <a style={{ fontSize: "13px"  }} href="#">
                          Remove
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Form>
          {flag === true ? (
            <div className="card-cart-wrap">
              <div className="float-right-link">
                <p
                  style={{ fontWeight: 700 }}
                  onClick={() => addAddress("add")}
                >
                  Add
                </p>
                {/* <p onClick={() => editAddress("edit")}>Edit</p>
              <p onClick={deleteAddress}>Delete</p> */}
              </div>
              <div className="float-left-section">
                <h5 className="price-item">Delivery Address</h5>
                {deliveryAddress.length > 0
                  ? deliveryAddress.map((delivery, index) => {
                      return (
                        <div key={index}>
                          {delivery !== null ? (
                            <div>
                              <div>
                                <p
                                  className="float-right-btn"
                                  style={{ float: "right" }}
                                  onClick={() => deleteAddress(delivery._id)}
                                >
                                  Delete
                                </p>
                                <p
                                  className="float-right-btn"
                                  onClick={() => editAddress("edit", index)}
                                >
                                  Edit
                                </p>
                              </div>
                              <div
                                style={{ display: "flex" }}
                                onClick={() => {
                                  setDeliveryAddressMethod(delivery);
                                }}
                              >
                                <input
                                  onChange={() => {
                                    setAddressSelected(false);
                                  }}
                                  checked={addressSelected === false}
                                  style={{ marginRight: 5, marginTop: 2 }}
                                  type="radio"
                                  name="radio"
                                  id={index}
                                />
                                <label htmlFor={index}>
                                  House No.{" "}
                                  {delivery !== null
                                    ? delivery.address.houseNumber
                                    : ""}
                                  <br />
                                  {delivery !== null
                                    ? delivery.address.streetName
                                    : ""}{" "}
                                  ,
                                  {delivery !== null
                                    ? delivery.address.district
                                    : ""}
                                  <br />
                                  {delivery !== null
                                    ? delivery.address.state
                                    : ""}{" "}
                                  ,
                                  {delivery !== null
                                    ? delivery.address.country
                                    : ""}
                                  <br />
                                  Pincode -{" "}
                                  {delivery !== null
                                    ? delivery.address.pinCode
                                    : ""}
                                  <br />
                                  Mob No.{" "}
                                  {delivery !== null
                                    ? delivery.phone.mobile
                                    : ""}
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  : ""}
              </div>

              <div className="btn-yellow">
                <p className="m-b-0">
                  <br /> <br />
                  (Based on your address)
                  <br />
                  Delievery Charges: Rs. 40
                  <br />
                  Delievery in 5-7 working days
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {addFlagStatus === true ? (
            <div className="card-cart-wrap">
              <h5 className="price-item">Delivery Address</h5>
              <div className="input-line">
                <input
                  placeholder="(Home / Building Name)"
                  className="input"
                  name="houseNumber"
                  value={inputField.houseNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="input-line">
                <input
                  placeholder="Street"
                  className="input"
                  name="streetName"
                  value={inputField.streetName}
                  onChange={handleChange}
                />
              </div>
              <div className="input-line">
                <input
                  placeholder="Pincode"
                  className="input"
                  name="pinCode"
                  value={inputField.pinCode}
                  onChange={(e) => {
                    handlePinCode(e.target.value);
                    handleChange(e);
                  }}
                  maxLength={6}
                />
              </div>
              <div className="input-line">
                <input
                  placeholder="District"
                  className="input"
                  name="district"
                  value={inputField.district}
                  onChange={handleChange}
                />
              </div>
              <div className="input-line">
                <input
                  placeholder="State"
                  className="input"
                  name="state"
                  value={inputField.state}
                  onChange={handleChange}
                />
              </div>
              <div className="input-line">
                <input
                  placeholder="Country"
                  className="input"
                  name="country"
                  value={inputField.country}
                  onChange={handleChange}
                />
              </div>
              <div className="input-line">
                <input
                  className="input"
                  placeholder="Mobile Number"
                  name="mobile"
                  value={inputField.mobile}
                  onChange={handleChange}
                  maxLength={10}
                />
              </div>
              <div className="input-line" style={{ textAlign: "right" }}>
                <input
                  className="skipBtn"
                  value="Save"
                  onClick={() => saveAddress(editBtn)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {editFlagValue === true
            ? deliveryAddress.length > 0
              ? deliveryAddress.map((delivery, index) => {
                  return (
                    <div key={index}>
                      {delivery !== null && index === indexValue ? (
                        <div className="card-cart-wrap">
                          <h5 className="price-item">Delivery Address</h5>
                          <div className="input-line">
                            <input
                              placeholder="(Home / Building Name)"
                              className="input"
                              name="houseNumber"
                              value={delivery.address.houseNumber}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              placeholder="Street"
                              className="input"
                              name="streetName"
                              value={delivery.address.streetName}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              placeholder="District"
                              className="input"
                              name="district"
                              value={delivery.address.district}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              placeholder="State"
                              className="input"
                              name="state"
                              value={delivery.address.state}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              placeholder="Country"
                              className="input"
                              name="country"
                              value={delivery.address.country}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              placeholder="Pincode"
                              className="input"
                              name="pinCode"
                              value={delivery.address.pinCode}
                              onChange={(e) => {
                                handlePinCode(e.target.value);
                                EditDeliveryAddress(e);
                              }}
                            />
                          </div>
                          <div className="input-line">
                            <input
                              className="input"
                              placeholder="Mobile Number"
                              name="mobile"
                              value={delivery.phone.mobile}
                              onChange={EditDeliveryAddress}
                            />
                          </div>
                          <div
                            className="input-line"
                            style={{ textAlign: "right" }}
                          >
                            <input
                              className="skipBtn"
                              value="Save"
                              onClick={() => updateAddress(editBtn, index)}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : ""
            : ""}
          <div
            className="fixedFooter"
            style={{ padding: 20, textAlign: "center" }}
          >
            {/* <div className="filter-icon">
              <img src={filter} alt="Filter" />
            </div> */}
            <button
              className=" btn-yellow-large"
              onClick={productOrder}
              style={{ marginTop: 2 }}
            >
              Proceed To Pay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, {
  configGateway,
  intiatePayment,
  finalizePayment,
  cancelOrder,
  postProductOrder,
  getPassbook,
  getCartItem,
  addRemoveToCart,
  getDeliveryAddress,
  updateDeliveryAddress,
  addDeliveryAddress,
  deleteDeliveryAddress,
  buyBullionOrderId,
})(Cart);
