import {
  TOKEN,
  GET_CUSTOM_INFO,
  GET_BULLION_RATE,
  GET_DETAILS,
  GET_AMOUNT,
  GET_LOCKER_VALUE,
  GET_ORDER_ID,
  SAVE_BANK_DETAILS,
  SAVE_AGENT_DETAILS,
  SAVE_CUSTOMER_SIP,
  SAVE_JEWELLERY_DETAILS,
  SAVE_PRODUCT_ORDER,PRODUCT_ID
} from "./../type";
const goldLoan = (state = {}, action) => {
  switch (action.type) {
    case TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case GET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };
    case GET_LOCKER_VALUE:
      return {
        ...state,
        lockerValue: action.payload,
      };
    case GET_CUSTOM_INFO:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_BULLION_RATE:
      return {
        ...state,
        price: action.payload,
      };
    case GET_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_AMOUNT:
      return {
        ...state,
        weight: action.payload,
      };
    case SAVE_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case SAVE_AGENT_DETAILS:
      return {
        ...state,
        agentData: action.payload,
      };
      case SAVE_CUSTOMER_SIP:
      return {
        ...state,
        customerSIP: action.payload,
      };  
      case SAVE_JEWELLERY_DETAILS:
      return {
        ...state,
        jewellery_details: action.payload,
      };  
      case SAVE_PRODUCT_ORDER:
      return {
        ...state,
        product_order: action.payload,
      }; 
      case PRODUCT_ID:
      return {
        ...state,
        product_id: action.payload,
      };
      
    default:
      return state;
  }
};

export default goldLoan;
