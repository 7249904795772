import React, {useEffect, useState} from "react";
import {Form, FormGroup, Label, Input} from "reactstrap";
import {connect} from "react-redux";
import {Icon} from "react-icons-kit";
import {angleLeft} from "react-icons-kit/fa/angleLeft";
import search from "../../images/jewellery/search-icon.svg";
import productItem from "../../images/jewellery/chain.svg";
import filter from "../../images/jewellery/filter-icon.svg";
import cart from "../../images/jewellery/cart.svg";
import {getProductList} from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import {PuffLoader} from "react-spinners";
import {css} from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const Transaction = (props) => {
    const [loader, setLoader] = useState(true);
    const [resData, setResData] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [flag, setFlag] = useState(true);
    useEffect(() => {
        let {data} = props.goldLoan ? props.goldLoan.userData : "";
        let reqData = {
            jwttoken: data.jwttoken,
            bullionName: "All",
            category: "",
        };

        props
            .getProductList(reqData)
            .then(async (res) => {
                setResData(res.data.result.otheritems);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const createSIPSubmit = () => {
        setLoader(true);
    };
    console.log("data", resData);

    return (
        <div style={{paddingBottom: 120}}>
            <div className="p-t-40">
                <div className="iconSize">
                    <Icon
                        size={24}
                        icon={angleLeft}
                        onClick={() => props.history.goBack("/")}
                    />
                </div>
            </div>

            {loader ? (
                <PuffLoader color="#FFD700" size={80} css={override}/>
            ) : (
                <div>

                    <div className="fixedFooter" style={{padding: 25}}>
                        {/* <div className="filter-icon">
                            <img src={filter} alt="Filter"/>
                        </div> */}
                        <button
                            className="savingPlanCSS btn-yellow-large"
                            onClick={createSIPSubmit}
                        >
                            Back to Home
                        </button>
                    </div>
                </div>

            )}
            <br/>
            <br/>
            <br/>
            <div className="page-name">Transaction Successful</div>
            <div className="card-wrap-blanks">
                <ul>

                    <li className='total'>
                        <div className='first-left'><strong>Order ID</strong></div>
                        <div className='second-left'>82362405107</div>
                    </li>
                </ul>

            </div>
            <div className="card-wrap-blank">
                <ul>
                    <li>
                        <div className='head'>Item</div>
                        <div className='head'>Quantity</div>
                        <div className='head'>Price</div>
                    </li>
                    <li>
                        <div>Name 1</div>
                        <div>1</div>
                        <div>Rs. 515</div>
                    </li>
                    <li>
                        <div>Name 1</div>
                        <div>1</div>
                        <div>Rs. 515</div>
                    </li>
                    <li className='seprator'>
                        <div>Name 1</div>
                        <div>1</div>
                        <div>Rs. 515</div>
                    </li>
                    <li className='seprator'>
                        <div className='first-left'>Total</div>
                        <div className='second-left'>RS. 3780</div>
                    </li>
                    <li>
                        <div className='first-left'>Making & Delivery Charges</div>
                        <div className='second-left'>Rs.60</div>
                    </li>
                    <li className='seprator'>
                        <div className='first-left'>GST</div>
                        <div className='second-left'>Rs. 60</div>
                    </li>
                    <li>
                        <div className='first-left'>Debited From</div>
                        <div className='second-left'>Locker</div>
                    </li>
                    <li className='seprator'>
                        <div className='first-left'>Debited From</div>
                        <div className='second-left'>UPI BHIM</div>
                    </li>

                    <li className='seprator'>
                        <div className='first-left'>Delivery By</div>
                        <div className='second-left'>28th-30th Mar, 2020</div>
                    </li>
                    <li className='seprator'>
                        <div className='head'>Update Locker Balance</div>
                        <div className='head'>0.133 gms</div>
                    </li>
                    <li className='total'>
                        <div className='first-left'></div>
                        <div className='first-left'>10:34 pm on 10 Aug 2019</div>
                        <div className='first-left'></div>
                    </li>
                </ul>

            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        goldLoan: state.goldLoan,
    };
};

export default connect(mapStateToProps, {getProductList})(Transaction);
