/* eslint-disable */
import React, {Suspense} from 'react'
import {Route, Switch,Redirect} from 'react-router-dom'
import { css } from "@emotion/react";
import {MoonLoader} from 'react-spinners'
import CreateSavingPlan from "./Components/SavingPlanner/CreateSavingPlan";
import SavingPlan from "./Components/SavingPlanner/SavingPlan";
import SavingPlanDetails from "./Components/SavingPlanner/SavingPlanDetails";
import SavingPlanFaqs from "./Components/SavingPlanner/SavingPlanFaqs";
import JewelleryMultiItem from "./Components/Jewellery/ProductListing";
import JewelleryProductDetails from "./Components/Jewellery/ProdcutDetails";
import BuyJewellary from './Components/Jewellery/BuyJewellary';
import Cart from "./Components/Jewellery/Cart";
import YourCart from "./Components/Jewellery/YourCart";
import Transaction from "./Components/Jewellery/Transaction";
import Filter from "./Components/Jewellery/Filter";
import Locker from "./Components/Jewellery/Locker";



const Gold = React.lazy(() =>
    import("./Components/Gold")
)
const GoldLoan = React.lazy(() =>
    import("./Components/GoldLoan")
)
const Buygold = React.lazy(() => 
    import("./Components/BuyGold/BuyGold")
)
const BuyInGrams = React.lazy(() =>
    import("./Components/BuyGold/BuyInGrams")
)
const BuyInRupee = React.lazy(() => 
    import("./Components/BuyGold/BuyInRupee")
)
const PersonalDetails = React.lazy(() => 
    import("./Components/BuyGold/PersonalDetails")
)
const Profile1 = React.lazy(() => 
    import("./Components/Profile1")
)
const SellGold = React.lazy(() =>
    import("./Components/SellGold/SellGold")
)
const SellInGrams = React.lazy(() =>
    import("./Components/SellGold/SellInGrams")
)
const SellInRupee = React.lazy(() =>
    import("./Components/SellGold/SellInRupee")
)
const UploadBankDetails = React.lazy(() => 
    import("./Components/SellGold/UploadBankDetails")
)
const BuyGoldStatus = React.lazy(() => 
    import("./Components/BuyGold/BuyGoldStatus")
)
const SellGoldStatus = React.lazy(() => 
    import("./Components/SellGold/SellTransaction")
)
const Otp = React.lazy(() => 
    import("./Components/Otp")
)
const openPdf = React.lazy(() => 
    import("./Components/openPdf")
)
const BankDetails = React.lazy(() => 
    import("./Components/SellGold/BankDetails")
)
const TransactionHistory = React.lazy(() => 
    import("./Components/SavingDiary/TransactionHistory")
)
const TransactionSuccessful = React.lazy(() => 
    import("./Components/SavingDiary/TransactionSuccessful")
) 
const ProfileDetails = React.lazy(() => 
    import("./Components/ProfileDetails")
)
const InventoryItem = React.lazy(()=>
import("./Components/Jewellery/Inventory"))
const Delivery = React.lazy(()=>
import("./Components/Jewellery/Delivery"))
const JewelleryOrderStatus = React.lazy(() => 
    import("./Components/Jewellery/JewelleryOrderStatus")
)




const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px
  
`;

export const Routes = () => {
    return (
        <div>
              <Suspense fallback={<MoonLoader css={override}/>}>
              <Switch>
              <Route exact path="/" component={Gold}/>
                 <Route exact path="/goldloan" component={GoldLoan}/>
                 <Route exact path="/Profile" component={Profile1}/>
                 <Route exact path="/buygold" component={Buygold} />
                 <Route exact path="/buyingrams" component={BuyInGrams}/>
                 <Route exact path="/buyinrupee" component={BuyInRupee}/>
                 <Route exact path="/personaldetails" component={PersonalDetails}/>
                 <Route exact path="/buygoldstatus" component={BuyGoldStatus}/>
                 <Route exact path="/sellgoldstatus" component={SellGoldStatus}/>
                 <Route exact path="/sellgold" component={SellGold}/>
                 <Route exact path="/sellingrams" component={SellInGrams}/>
                 <Route exact path="/sellinrupee" component={SellInRupee}/>
                 <Route exact path="/uploadbankdetails" component={UploadBankDetails}/>
                 <Route exact path="/otp" component={Otp}/> 
                 <Route exact path="/openPdf" component={openPdf}/> 
                 <Route exact path="/bankdetails" component={BankDetails}/> 
                 <Route exact path="/transactionhistory" component={TransactionHistory}/> 
                 <Route exact path="/transactionsuccessful" component={TransactionSuccessful}/>  
                 <Route exact path="/profileDetails" component={ProfileDetails}/>
                 <Route exact path="/create-saving-plan" component={CreateSavingPlan}/>
                 <Route exact path="/saving-plan" component={SavingPlan}/>
                 <Route exact path="/saving-plan-details" component={SavingPlanDetails}/>
                 <Route exact path="/saving-plan-faqs" component={SavingPlanFaqs}/>
                 <Route exact path="/product-listing" component={JewelleryMultiItem}/>
                 <Route exact path="/product-details" component={JewelleryProductDetails}/>
                 <Route exact path="/buy-jewellery" component={BuyJewellary}/>
                 <Route exact path="/cart" component={Cart}/>
                 <Route exact path="/yourCart" component={YourCart}/>
                 <Route exact path="/transaction" component={Transaction}/>
                 <Route exact path="/filter" component={Filter}/>
                 <Route exact path="/locker" component={Locker}/>
                 <Route exact path="/delivery" component={Delivery}/>
                 <Route exact path="/inventory" component={InventoryItem}/>  
                 <Route exact path="/jewellery-order-status" component={JewelleryOrderStatus}/>
                 <Redirect to="/" />
            </Switch>
            </Suspense>
        </div>
    )
}
