import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import star from "../../images/star.png";
import { createSIP, getSavingPlan, getCustomerSips } from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const CreateSavingPlan = (props) => {
  const [resData, setResData] = useState([]);
  const [show, setShow] = useState(false);
  const [daily, setDaily] = useState(true);
  const [weekly, setWeekly] = useState(false);
  const [fortnight, setFortnight] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [savingObjective, setSavingObjective] = useState("");
  const [savingObjective1, setSavingObjective1] = useState("");
  const [saveApprox, setSaveApprox] = useState("");
  const [frequency, setFrequency] = useState("");
  const [period, setPeriod] = useState("");
  const [targetAmountInr, setTargetAmountInr] = useState("");
  const [saveApproxerr, setSaveApproxerr] = useState("");
  const [perioderr, setPerioderr] = useState("");
  const [savingObjectiveerr, setSavingObjectiveerr] = useState("");
  const [frequencyerr, setFrequencyerr] = useState("");
  const [loader, setLoader] = useState(false);
  const [ObjectiveOption, setObjectiveOption] = useState("")

  useEffect(() => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    props
      .getSavingPlan(data)
      .then(async (res) => {
        setResData(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const createSIPSubmit = () => {
   
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
    };
    let multple = period * saveApprox;

    if (savingObjective.length === 0) {
      setSavingObjectiveerr("SavingObjective can't be empty");
      return false;
    }
    if (savingObjective1.length === 0 && savingObjective === "Other") {
      setSavingObjectiveerr("Enter saving objective");
      return false;
    }
    if (frequency === "") {
      setFrequencyerr("Frequency can't be empty");
      return false;
    }

    if (period === "") {
      setPerioderr("Saving period can't be empty");
      return false;
    }
  if (period < 2) {
      setPerioderr("Saving period should be at minimum 2");
      return false;
    }

    if (saveApprox === "") {
      setSaveApproxerr(
        "Can't be empty."
      );
      return false;
    }

    if (saveApprox < 500 ) {
      setSaveApproxerr(
        "Can't be empty or Save Approx should be at minimum 500 "
      );
      return false;
    }

    if (period > 365 && frequency === "daily" ) {
      setPerioderr(
        "Saving periods can't be more than 365 days "
      );
      return false;
    }
    if (period > 52 && frequency === "weekly" ) {
      setPerioderr(
        "Saving periods can't be more than 52 weeks "
      );
      return false;
    }
    if (period > 24 && frequency === "fortnightly" ) {
      setPerioderr(
        "Saving periods can't be more than 14 fortnight "
      );
      return false;
    } 


    let createData = {
      jwttoken: reqData.jwttoken,
      SavingObjective: savingObjective === "Other" ? savingObjective1 : savingObjective,
      SaveApprox: Number(saveApprox),
      frequency: frequency,
      SavingPeriod: Number(period),
      sipTarget: {
        targetType: "FixedAmount",
        targetAmountInr: multple,
      },
    };

    setLoader(true);
    props
      .createSIP(createData)
      .then(async (res) => {
        props
        .getCustomerSips(reqData)
        .then(async (res) => {
          setLoader(false);
          props.history.push("/saving-plan")
        //   props.history.push({
        //   pathname: "/saving-plan",
        //   state: { savingPlanValue: res.data.result },
        //   // setCreateSIP(res.data.result);
        // });
        })
        .catch((err) => {
          console.log(err);
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
           setPeriod(value);
  }
  return (
   
    <>
      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div style={{paddingBottom:120}}>
          <div className="p-t-40">
            <div className="iconSize">
              <Icon
                size={24}
                icon={angleLeft}
                onClick={() => props.history.goBack("/")}
              />
            </div>
            <div className="page-name">New Saving Plan</div>
          </div>

          <Form className="p-l-30 p-r-30 p-t-40">
            <FormGroup>
              <Label for="savingObjective" className="select-label">
                Saving Objective
              </Label>
              <Input
                type="select"
                name="savingObjective"
                className="select-box"
                onChange={(e) => {
                  if (e.target.selectedOptions[0].text === "Other") {
                    setShow(true);
                  } else {
                    setShow(false);
                  }
                  setSavingObjective(e.target.value);
                  setSavingObjectiveerr("");
                }}
              >
                <option value="0" defaultValue>
                  Select
                </option>
                {resData.map((item) => (
                  <option key={item._id} value={item.objectivename}>
                    {item.objectivename}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {show ? (
              <FormGroup>
                <div className="box-row">
                  <Input
                    type="text"
                    name="savingObjective"
                    onChange={(e) => {
                      setSavingObjective1(e.target.value);
                      setSavingObjectiveerr("");
                    }}
                    placeholder="Enter saving objective"
                    className="select-input-p"
                  />
                </div>
              </FormGroup>
            ) : null}
            {savingObjectiveerr ? (
              <span style={{ color: "red" }}>{savingObjectiveerr}</span>
            ) : null}
            <FormGroup>
              <Label for="frequency" className="select-label">
                Frequency
              </Label>
              <Input
                type="select"
                name="frequency"
                className="select-box"
                onChange={(event) => {
                  console.log(event.target.value)
                  setFrequency(event.target.value);
                  setFrequencyerr("");
                  
                  if (event.target.value === "monthly") {
                    setSaveApprox("");
                    setPeriod("");
                    setMonthly(true);
                  } else {
                    setSaveApprox("");
                    setPeriod("");
                    setMonthly(false);
                  }
                  if (event.target.value === "daily"  ) {
                    setSaveApprox("");
                    setPeriod("");
                    setDaily(true);
                  } else {
                    setSaveApprox("");
                    setPeriod("");
                    setDaily(false);
                  }
                  if (event.target.value === "weekly") {
                    setSaveApprox("");
                    setPeriod("");
                    setWeekly(true);
                  } else {
                    setSaveApprox("");
                    setPeriod("");
                    setWeekly(false);
                  }
                  if (event.target.value === "fortnightly") {
                    setSaveApprox("");
                    setPeriod("");
                    setFortnight(true);
                  } else {
                    setSaveApprox("");
                    setPeriod("");
                    setFortnight(false);
                  }
                }}
              >
                <option value="">Select</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="fortnightly">Fortnightly</option>
                <option value="monthly">Monthly</option>
              </Input>
             
            </FormGroup>
            {frequencyerr ? (
              <span style={{ color: "red" }}>{frequencyerr}</span>
            ) : null}
            <FormGroup>
              <Label for="period" className="select-label">
                Saving Period
              </Label>
              { weekly ? (
                <Input
                  type="number"
                  name="savingperiod"
                  placeholder="Enter saving period"
                  className="select-input-p"
                  onChange={(e) => {
                    setPeriod(e.target.value.replace(/\D/g, ""));
                    setPerioderr("");
                  }}
                  value={period}
                />
              ) : fortnight ? (
                <Input
                  // type="text"
                  name="savingperiod"
                  placeholder="Enter saving period"
                  className="select-input-p"
                  onChange={(e) => {
                    setPeriod(e.target.value.replace(/\D/g, ""));
                    setPerioderr("");
                  }}
                  value={period}
                />
              ) : monthly ? (
                <Input
                  type="select"
                  name="period"
                  className="select-box"
                  onChange={(e) => {
                    setPeriod( e.target.value.replace(/\D/g, ""));
                    setPerioderr("");
                  }}
                  value={period}
                >
                  <option value="" defaultValue>
                    Select saving period
                  </option>
                  <option value="3">3 Months</option>
                  <option value="6">6 Months</option>
                  <option value="9">9 Months</option>
                  <option value="12">12 Months</option>
                  <option value="15">15 Months</option>
                  <option value="18">18 Months</option>
                  <option value="21">21 Months</option>
                  <option value="24">24 Months</option>
                </Input>
              ) :  (
                <Input
                  name="savingperiod"
                  placeholder="Enter saving period"
                  className="select-input-p"
                  onChange={(e) => {
                      setPeriod(e.target.value.replace(/\D/g, ""));
                      setPerioderr("");
                   
                  }}
                  value={period}
                />
              ) }
            </FormGroup>
            {perioderr ? (
              <span style={{ color: "red" }}>{perioderr}</span>
            ) : null}
            <FormGroup>
              <span className="required">*</span>
              <Label for="save" className="select-label">
                Save Approx
              </Label>
              <div className="box-row">
                <span className="left-top">Rs</span>

                {weekly ? (
                  <>
                    {" "}
                    <Input
                      // type="text"
                      name="saveApprox"
                      placeholder="Save Approx amount"
                      className="select-input-btm"
                      onChange={(e) => {
                        setSaveApprox( e.target.value.replace(/\D/g, ""));
                        setSaveApproxerr("");
                      }}
                      value={saveApprox}
                    />
                    <span className="right-top">/Week</span>{" "}
                  </>
                ) : fortnight ? (
                  <>
                    <Input
                      // type="text"
                      name="saveApprox"
                      placeholder="Save Approx amount"
                      className="select-input-btm"
                      onChange={(e) => {
                        setSaveApprox( e.target.value.replace(/\D/g, ""));
                        setSaveApproxerr("");
                      }}
                      value={saveApprox}
                    />
                    <span className="right-top">/Fortnight</span>
                  </>
                ) : monthly ? (
                  <>
                    <Input
                      // type="text"
                      name="saveApprox"
                      placeholder="Save Approx amount"
                      className="select-input-btm"
                      onChange={(e) => {
                        setSaveApprox( e.target.value.replace(/\D/g, ""));
                        setSaveApproxerr("");
                      }}
                      value={saveApprox}
                    />
                    <span className="right-top">/Month</span>
                  </>
                ) : (
                  <>
                    <Input
                      // type="text"
                      name="saveApprox"
                      placeholder="Save Approx amount"
                      className="select-input-btm"
                      onChange={(e) => {
                        setSaveApprox( e.target.value.replace(/\D/g, ""));
                        setSaveApproxerr("");
                      }}
                      value={saveApprox}
                    />
                    <span className="right-top">/day</span>{" "}
                    
                  </>
                ) }
              </div>
              {saveApproxerr ? (
                <span style={{ color: "red" }}>{saveApproxerr}</span>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="targetsaving" className="select-label">
                Target saving
              </Label>
              <div className="box-row">
                <Input
                  style={{ paddingLeft: 50 }}
                  name="targetAmountInr"
                  id="targetAmountInr"
                  type="text"
                  value={period * saveApprox}
                  className="select-input-p"
                  readOnly
                />
                {/* <Label className='select-label p-t-10 p-l-10 p-r-10'
                        >OR</Label>
                        <Input
                            name="targetsaving"
                            type="text"
                            className='select-input'
                            value={"    " }
                        />
                        <span className='right-top'>gms    &nbsp; </span> */}
                <span className="left-top"> &nbsp; Rs</span>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="direction-row">
                <img className="img-fluid maxWidth40" src={star} alt="loan" />
                <Label for="content" className="comments">
                  This saving calculator is based on historical gold price and
                  the actual saved amount and will depend on the prescribing
                  gold rate at the time of purchase.
                </Label>
              </div>
            </FormGroup>
            {/*     <FormGroup>
                    <Label for="debit" className='select-label'>Auto Debit Mandate</Label>
                    <div className='direction-row'>
                        <Input
                            type="checkbox"
                            name="debit"
                            className='select-checked'
                        >
                        </Input>
                        <Label for='debit' className='confirmation'>I hereby authorise you to auto-debit for
                            SIP</Label>
                    </div>
                </FormGroup>*/}
          </Form>
          <div className="fixedFooter text-center" style={{ padding: 25 }}>
            <button
              className="savingPlanCSS btn-yellow-large"
              onClick={createSIPSubmit}
            >
              Create
            </button>
          </div>
          {/* <div className="footer">
                          <div className='text-center padding-30'>
                          <button className='btn-yellow-large'   onClick={createSIPSubmit}>
                            Create
                          </button>
                        </div>
                      </div> */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { createSIP, getSavingPlan, getCustomerSips })(
  CreateSavingPlan
);
