import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import star from "../../images/savingplan/star.png";
import jutice from "../../images/savingplan/circle.png";
import circleGreen from "../../images/savingplan/circleGreen.png";
import img1 from "../../images/savingplan/img1.png";
import blackStar from "../../images/savingplan/blackStar.png";
import { getSipsFaqs } from "../../Redux/ApiCall/action";
import {Icon} from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import Collapsible from 'react-collapsible';


const SavingPlanFaqs = (props) => {
  const [faqsList, setFaqsList] = useState([]);
  const [customerSIP, setCustomerSIP] = useState([]);
  const [imgArray, setImageArray] = useState([img1,jutice,circleGreen,blackStar]);

  const imgRender = (i) => {
    if(imgArray.length > i )
    return(
      <img className="img-fluid maxWidth40" src={imgArray[i]} alt="loan" />
    )
    else {

      return(
        <img className="img-fluid maxWidth40" src={imgArray[i-imgArray.length]} alt="loan" />
      ) 
    }
    // let counter = 0;
    //   imgArray.map((images) => {
    //     return(

    //     )
    //   })
  }

  useEffect(() => {
    let customdata = props.goldLoan
    ? props.goldLoan.customerSIP
      ? props.goldLoan.customerSIP.result
      : ""
    : "";
   setCustomerSIP(customdata);
   let { data } = props.goldLoan ? props.goldLoan.userData : "";
   let data1 = {
    jwttoken: data.jwttoken,
  };
    props
      .getSipsFaqs(data1)
      .then((res) => {
        setFaqsList(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="p-t-40">
        <div className="iconSize">
          <Icon
              size={24}
              icon={angleLeft}
              onClick={() => props.history.goBack("/goldloan")}
          />
        </div>
        <div className='page-name'>
          New Saving Plan
        </div>
      </div>
      <div className="card-shadow">
        <div className="float-left">
          <img src={star} alt="loan" width ="90" />
        </div>
        <p className="float-left">
          Save regularly to fulfil a cherished life goal or towards a gift for a
          special occasion or festival
        </p>
      </div>
      <div className="card-cantainer">
        {faqsList.length !== 0
          ? faqsList.map((item,index) => {
              return (
                <div className="card-row" key={index}>
                  <div className="card-circle">
                    <div>
                    {imgRender(index)}
                    </div>
                    {/* <img className="img-fluid maxWidth40" src={img1} alt="loan" /> */}
                  </div>
                  <div className="card-center">
                      <Collapsible trigger={item.question}>
                          <p>{item.answer}</p>
                      </Collapsible>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="fixedFooter" style={{padding: 25}}>
       {customerSIP.length === 0 ? (
          <button
            className="savingPlanCSS btn-yellow-large"
            onClick={() => props.history.push("/create-saving-plan")}
          >
            New Saving plan
          </button>
         ) : (
          <div>
                    <button
                     className="savingPlanCSS float-left btn-yellow-medium"
                    //  style={{marginLeft: 20}}
                      onClick={() => props.history.push("/create-saving-plan")}
                    >
                      New Saving plan
                    </button>
                      <button
                       className="savingPlanCSS float-right btn-yellow-medium"
                      //  style={{marginRight: 20}}
                        onClick={() => props.history.push("/saving-plan")}
                      >
                        Current Saving plan
                      </button>
            </div> 
        )
        }     
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { getSipsFaqs })(
  SavingPlanFaqs
);
