
import { combineReducers } from "redux";
import goldLoan from './ApiCall/reducer';


const appReducer = combineReducers({
        goldLoan:goldLoan,
});

export default appReducer;
