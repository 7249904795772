import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import gift from "../../images/savingplan/gift.png";
import moment from "moment";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import {
  sipOrder,
  buyBullion,
  buyBullionOrderId,
  configGateway,
  intiatePayment,
  finalizePayment,
  getPassbook,
  cancelOrder,
} from "../../Redux/ApiCall/action";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;

const SavingPlanFaqs = (props) => {
  const [customerSIP, setCustomerSIP] = useState([]);
  const [loader, setLoader] = useState(false);
  const [price, setPrice] = useState();
  const [gst, setGst] = useState("");
  const [installmentAmt, setInstallmentAmt] = useState(""); 
  const [sipId, setSIPId] = useState("");
  const [proceed, setProceed] = useState(false);
  const [userDetails, setUserDetails] = useState(props.goldLoan.userData.data);

  useEffect(() => {
    let data = props.goldLoan
      ? props.goldLoan.customerSIP
        ? props.goldLoan.customerSIP.result
        : ""
      : "";
    setCustomerSIP(data);

    let buyObject = props.goldLoan ? props.goldLoan.price.result : "";
    setPrice(buyObject.sipbuy.sipBuyRate);

    let taxRate = buyObject.sipbuy.taxRates;
    let total = 0;
    taxRate.map((item) => {
      total = total + item.taxRatePercent;
    });
    setGst(total);
  }, []);

  const check = (dataValue) => {
    let requsetData = {
      jwttoken: dataValue.razorPayData.jwttoken,
      gateway: {
        gateway: "razorpay",
        config: { key_id: dataValue.razorPayData.key_id },
      },
      txnreference: dataValue.razorPayData.txnreference,
      orderId: dataValue.razorPayData.orderId,
      total: dataValue.razorPayData.totalAmount,
      ordernumber: dataValue.razorPayData.orderNumber,
      gateway_response: {
        checkout_logo: dataValue.responseData.checkout_logo,
        custom_branding: dataValue.responseData.custom_branding,
        org_logo: dataValue.responseData.org_logo,
        org_name: dataValue.responseData.org_name,
        razorpay_order_id: dataValue.responseData.razorpay_order_id,
        razorpay_payment_id: dataValue.responseData.razorpay_payment_id,
        razorpay_signature: dataValue.responseData.razorpay_signature,
      },
      orderType : "sip"
    };
   
    setLoader(true);
    props
      .finalizePayment(requsetData)
      .then((res) => {
        setLoader(false);
        props.history.push({
          pathname: "/buygoldstatus",
          state: { result: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayRazorpay = (razorPayData) => {
    let cancleOrderData = {
      jwttoken: razorPayData.jwttoken,
      order_uuid: razorPayData.order_uuid,
      reason: "Payment processing cancelled by user",
      ordernumber: razorPayData.orderNumber,
    };

    const options = {
      key: razorPayData.key_id,
      amount: razorPayData.totalAmount,
      currency: "INR",
      name: userDetails.name.first + "  " + userDetails.name.last,
      description: "Gold Investement",
      // image: { logo },
      order_id: razorPayData.txnreference,
      handler: function (response) {
        let dataValue = {
          razorPayData: razorPayData,
          responseData: response,
        };
        check(dataValue);
      },
      prefill: {
        name: userDetails.name.first + "  " + userDetails.name.last,
        email: userDetails.email,
        contact: userDetails.mobile,
      },
      notes: {
        address: "Payme India",
      },
      theme: {
        color: "#61dafb",
      },
      modal: {
        escape: false,
        ondismiss: function () {
          props
            .cancelOrder(cancleOrderData)
            .then((res) => {
              console.log("cancel order response", res);
            })
            .catch((err) => {
              console.log(err);
            });
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      let cancleOrder = {
        jwttoken: razorPayData.jwttoken,
        order_uuid: razorPayData.order_uuid,
        reason: response.error.reason,
        ordernumber: razorPayData.orderNumber,
      };
      props
        .cancelOrder(cancleOrder)
        .then((res) => {
          paymentObject.close();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const buyNow = () => {
   
    let weight = (installmentAmt / price / (gst / 100 + 1)).toFixed(3);
    setLoader(true);
    let agentData = props.goldLoan ? props.goldLoan.agentData : "";
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let orderId = "",
      netAmount = "",
      gatewayType = "",
      keyId = "";
    let order = {
      jwttoken: data.jwttoken,
      agent: {
        extAgentId: agentData.result.extAgentId,
        name: {
          first: agentData.result.name.first,
          last: agentData.result.name.last,
        },
      },
      sipId: sipId,
    orderTotalValueInr: installmentAmt
      // weightInGm: parseFloat(weight),
      // jwttoken: data.jwttoken,
      // buyType: "FixedAmount",
    };
    props
      .sipOrder(order)
      .then((res) => {
        if (res) {
          let sip_orderNumber = res.data.result.ordernumber;
          let sip_order_uuid = res.data.result.id;
          // orderId = res.data.result.id,
          netAmount = res.data.result.orderTotalValueInr;
          props.buyBullionOrderId({
            order_uuid: res.data.result.id,
            ordernumber: res.data.result.ordernumber,
          });
          let obj = {
            txnType: "sip",
            jwttoken: data.jwttoken,
            orderId: sip_order_uuid,
          };
          props
            .configGateway(obj)
            .then((response) => {
              if (response) {
                gatewayType = response.data.result.gateway;
                keyId = response.data.result.config.key_id;
                let paymentData = {
                  jwttoken: data.jwttoken,
                  gateway: {
                    gateway: gatewayType,
                    config: {
                      key_id: keyId,
                    },
                  },
                  orderId: sip_order_uuid,
                  total: netAmount,
                };
                props
                  .intiatePayment(paymentData)
                  .then((resp) => {
                    if (resp) {
                      setLoader(false);
                      let razorPayData = {
                        key_id: keyId,
                        orderId: resp.data.result.notes.orderId,
                        txnreference: resp.data.result.id,
                        totalAmount: netAmount,
                        jwttoken: data.jwttoken,
                        orderNumber: sip_orderNumber,
                        order_uuid: sip_order_uuid,
                      };
                      displayRazorpay(razorPayData);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectCard = (value,id) => {
    setInstallmentAmt(value);
    setSIPId(id);
    setProceed(true);
  };

  return (
    <div style={{ paddingBottom: 90 }}>
       {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
      <div>
      <div className="p-t-40">
        <div className="iconSize">
          <Icon
            size={24}
            icon={angleLeft}
            onClick={() => props.history.goBack("/")}
          />
        </div>
        <div className="page-name">New Saving Plan</div>
      </div>
      {customerSIP.length !== 0
        ? customerSIP.map((item) => {
            return (
              <div
                className="box-card"
                onClick={() => selectCard(item.sipInstallmentAmtInr,item.id)}
              >
                <div className="top_img">
                  <img className="img-fluid" src={gift} alt="loan" />
                </div>
                <div className="edit_right">
                  <i className="fa fa-pencil"  aria-hidden="true" style={{color : "#D5B038", fontSize : 14}} 
                     onClick={() =>
                      props.history.push({
                        pathname: "/saving-plan-details",
                        state: { savingPlanValue: item },
                      })
                    }></i>

                  {/* <button
                    onClick={() =>
                      props.history.push({
                        pathname: "/saving-plan-details",
                        state: { savingPlanValue: item },
                      })
                    }
                  >
                    Edit
                  </button> */}
                </div>
                <div className="head2">
                  <h2>{item.milestoneName}</h2>
                  <p>Save By : {moment(item.startDate).format("DD/MM/YYYY")}</p>
                </div>
                <div className="wrapper-box-card">
                  <div className="headLeft">
                    <strong>Achieved</strong>
                    <p>Rs.{item.sipTracking.collectedAmountInr}</p>
                  </div>
                  <div className="headRight">
                  <strong>Target</strong>
                    <p>Rs.{item.sipTarget.targetAmountInr}</p>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      <div className="fixedFooter">
        {proceed ? (
          <div className="btnCenter">
            <button className="footer-btn-css" onClick={() => buyNow()}>
              Proceed To Pay
            </button>
          </div>
        ) : (
          <div className="btnCenter">
            <button
              className="footer-btn-css"
              style={{
                background: "#f2e6c0",
              }}
            >
              Proceed To Pay
            </button>
          </div>
        )}
      </div>
      </div>
       ) }
  </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, {
  sipOrder,
  buyBullion,
  buyBullionOrderId,
  configGateway,
  intiatePayment,
  finalizePayment,
  getPassbook,
  cancelOrder,
})(SavingPlanFaqs);
