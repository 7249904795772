import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import search from "../../images/jewellery/search-icon.svg";
import productItem from "../../images/jewellery/chain.svg";
import filter from "../../images/jewellery/filter-icon.svg";
import { getProductList, getProductDetails,saveProductID } from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const ProductListing = (props) => {
  console.log(props.history.location.state,"props.history.location.state.");
  const [loader, setLoader] = useState(true);
  const [resData, setResData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [invetorydata, setInventorydata] = useState([]);
  const [productname, setProductName] = useState("");
  const [productdecription, setProductDecription] = useState("");
  const [flag, setFlag] = useState(props.history.location.state.flagValue);
  useEffect(() => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      bullionName: "All",
      category: "",
    };

    props
      .getProductList(reqData)
      .then(async (res) => {
        setResData(res.data.result.otheritems);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
      if(flag === false){
        let e = props.goldLoan ? props.goldLoan.product_id : "";
        getInventoryDetails(e);
      }
  }, []);

  const getInventoryDetails = (e) => {
    setLoader(true);
    props.saveProductID(e);
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,

      productid: e,
    };

    props
      .getProductDetails(reqData)
      .then(async (res) => {
        setFlag(false);
        if (res.data.result.images[0]) {
          setImageUrl(res.data.result.images[0].url);
        } else {
          setImageUrl(
            "https://test-partnernapi-1.dvarasmartgold.in/publicApi/productImage/47be5034-b3c3-41b1-9e09-d87acfc33c21.jpg"
          );
        }
        setInventorydata(res.data.result.inventoryitems);
        setProductName(res.data.result.productname);
        setProductDecription(res.data.result.productdescription);
        console.log("res of de", res.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSIPSubmit = () => {
    setLoader(true);
    setFlag(true)
    setLoader(false)
  };

  const changeFlagValue = () => {
    setFlag(true);
  }
 
  return (
    <div style={{ paddingBottom: 120 }}>
      <div className="p-t-40">
        <div className="iconSize">
          {flag ?
          <Icon
          size={24}
          icon={angleLeft}
          onClick={() => props.history.push("/goldloan")}
          /> : 
          <Icon
          size={24}
          icon={angleLeft}
          onClick={changeFlagValue}
        /> }
          
        </div>

        {flag ? (
          <div className="page-name">Jewellery</div>
        ) : (
          <div className="page-name">Inventory</div>
        )}
      </div>

      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div>
          <Form className=" p-t-20">
            {/* <FormGroup className="p-l-30 p-r-30">
              <div className="box-row position-relative">
                <img src={search} className="search-icon" alt="Search icon" />
                <Input
                  type="text"
                  name="search"
                  placeholder="Search"
                  className="search-input"
                />
              </div>
            </FormGroup> */}
            {flag ? (
              <div className="card-jewellery-box">
                {resData.map((item) => {
                  return (
                    <div
                      className="card-jewellery"
                      onClick={() => getInventoryDetails(item.productid)}
                    >
                      {item.images[0] ? (
                        <img src={item.images[0].url} alt="Gold Chain" />
                      ) : (
                        <img
                          src="https://test-partnernapi-1.dvarasmartgold.in/publicApi/productImage/47be5034-b3c3-41b1-9e09-d87acfc33c21.jpg"
                          alt="Gold Chain"
                        />
                      )}
                      <p>{item.productname}</p>
                      <h5 className="price-item">
                        {parseInt(item.pricerangeinr.from)}-
                        {parseInt(item.pricerangeinr.to)}
                      </h5>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="card-jewellery-box">
                {invetorydata.map((item) => {
                  return (
                    <div
                      className="card-jewellery"
                      onClick={() =>
                        props.history.push("/product-details", {
                          inventorydetails: item,
                          imageUrl: imageUrl,
                          productname: productname,
                          productdescription:productdecription,
                        })
                      }
                    >
                      {imageUrl ? (
                        <img src={imageUrl} alt="Gold Chain" />
                      ) : null}
                      <p>
                        {item.weightingm}gm-
                        {item.convertedBullionWt.bullion.purity.displayValue}(
                        {item.convertedBullionWt.bullion.purity.value}){" "}
                        {item.convertedBullionWt.bullion.bullionName}
                      </p>
                      <h5 className="price-item">
                        {parseInt(item.totalwithtaxinr)}
                      </h5>
                      <h5 className="price-item">{productname}</h5>
                    </div>
                  );
                })}
              </div>
            )}
          </Form>
          {/* <div className="fixedFooter">
            <div className="btnCenter">
              <button
                className="allCategoryJewellary"
                onClick={() => createSIPSubmit()}
              >
                View All Categories
              </button>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { getProductList, getProductDetails,saveProductID })(
  ProductListing
);
