import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import search from "../../images/jewellery/search-icon.svg";
import productItem from "../../images/jewellery/chain.svg";
import filter from "../../images/jewellery/filter-icon.svg";
import { getProductList, getProductDetails } from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const Inventory = (props) => {
  console.log("props for inventory", props);
  const [loader, setLoader] = useState(true);
  const [resData, setResData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [invetorydata, setInventorydata] = useState([]);
  const [productname, setProductName] = useState("");
  const [productdecription, setProductDecription] = useState("");

  useEffect(() => {
    let jwttoken = props.location.state.data_inventory.jwttoken;
    let reqData = {
      jwttoken: jwttoken,
      productid: props.location.state.data_inventory.productid,
    };

    props
      .getProductDetails(reqData)
      .then(async (res) => {
        setResData(res.data.result.otheritems);

        if (res.data.result.images[0]) {
          setImageUrl(res.data.result.images[0].url);
        } else {
          setImageUrl(
            "https://test-partnernapi-1.dvarasmartgold.in/publicApi/productImage/47be5034-b3c3-41b1-9e09-d87acfc33c21.jpg"
          );
        }
        setInventorydata(res.data.result.inventoryitems);
        setProductName(res.data.result.productname);
        setProductDecription(res.data.result.productdescription);
        console.log("res of de", res.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div style={{ paddingBottom: 120 }}>
      <div className="p-t-40">
        <div className="iconSize">
          <Icon
            size={24}
            icon={angleLeft}
            onClick={() => props.history.goBack("/")}
          />
        </div>

       
          <div className="page-name">Inventory</div>
      
      </div>

      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div>
          <Form className=" p-t-20">
            {/* <FormGroup className="p-l-30 p-r-30">
              <div className="box-row position-relative">
                <img src={search} className="search-icon" alt="Search icon" />
                <Input
                  type="text"
                  name="search"
                  placeholder="Search"
                  className="search-input"
                />
              </div>
            </FormGroup> */}

            <div className="card-jewellery-box">
              {invetorydata.map((item) => {
                return (
                  <div
                    className="card-jewellery"
                    onClick={() =>
                      props.history.push("/product-details", {
                        inventorydetails: item,
                        imageUrl: imageUrl,
                        productname: productname,
                        productdescription: productdecription,
                      })
                    }
                  >
                    {imageUrl ? <img src={imageUrl} alt="Gold Chain" /> : null}
                    <p>
                      {item.weightingm}gm-
                      {item.convertedBullionWt.bullion.purity.displayValue}(
                      {item.convertedBullionWt.bullion.purity.value}){" "}
                      {item.convertedBullionWt.bullion.bullionName}
                    </p>
                    <h5 className="price-item">
                      {parseInt(item.totalwithtaxinr)}
                    </h5>
                    <h5 className="price-item">{productname}</h5>
                  </div>
                );
              })}
            </div>
          </Form>
          <div className="fixedFooter" style={{ padding: 25 }}>
           
            <button
              className="savingPlanCSS btn-yellow-large"
              onClick={() => {
                props.history.push("/product-listing");
              }}
            >
              View All Categories
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { getProductList, getProductDetails })(
  Inventory
);
