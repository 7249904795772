import React, { Component } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import star from "../../images/star.png";
import gift from "../../images/savingplan/gift.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import moment from "moment";

import {
  getSavingPlan,
  createSIP,
  getCustomerSips,
  updateSIP,
} from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;

class SavingPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMessage : false,
      open: false,
      saveAmount: 100,
      displayModal: false,
      customerSIP: props.location ? props.location.state.savingPlanValue : "",
      showOption: false,
      resData: [],
      show: false,
      frequency: props.location
        ? props.location.state.savingPlanValue.frequency
        : "",
      daily: true,
      weekly: false,
      fortnight: false,
      monthly: false,
      createSIP: [],
      savingObjective: props.location
        ? props.location.state.savingPlanValue.milestoneName
        : "",
      saveApprox: props.location
        ? props.location.state.savingPlanValue.sipInstallmentAmtInr
        : "",
      period: props.location
        ? props.location.state.savingPlanValue.numberofInstallments
        : "",
      // period: "",
      targetType: "",
      targetAmountInr: props.location
        ? props.location.state.savingPlanValue.sipTarget.targetAmountInr
        : "",
      saveApproxerr: "",
      perioderr: "",
      savingObjectiveerr: "",
      savingObjectiveerrerr:"",
      frequencyerr: "",
      loader: false,
      periodStateValue : false,
      inputType : "text" ,
      inputState : false,
      savingObjective1: "",
      checkData : false,
      count : 1,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        daily: this.state.frequency === "daily" ? true : false,
        weekly: this.state.frequency === "weekly" ? true : false,
        fortnight: this.state.frequency === "fortnightly" ? true : false,
        monthly: this.state.frequency === "monthly" ? true : false,
      },
      () => {
      }
    );

    let { data } = this.props.goldLoan ? this.props.goldLoan.userData : "";
    this.props
      .getSavingPlan(data)
      .then(async (res) => {
        this.setState({ resData: res.data.result },() => {
          this.state.resData.map((item) => {
            if(item.objectivename === this.state.savingObjective){
              this.setState({inputType: "select"})
              this.setState({checkData: true})
            }
          })
        });
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openSaveMessage = (scrollType) => {

    this.setState({
      open:false,
      openMessage: true,
      scroll: scrollType,
    });
  };

  message = () => {
      this.props.history.push("/saving-plan");
  };

  handleClickOpen = (scrollType) => {

    this.setState({
      open: true,
      scroll: scrollType,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  // handleChange = (event) => {
  //   if (event.target.value === "other") {
  //     this.setState({ showOption: true });
  //   } else {
  //     this.setState({ showOption: false });
  //   }
  // };

  CallSipApi = () => {
    let {
      period,
      saveApprox,
      savingObjective,
      savingObjective1,
      frequency
    } = this.state;
    let { data } = this.props.goldLoan ? this.props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
    };
    let multple = period * saveApprox;
    let rowData = {
      jwttoken: reqData.jwttoken,
      sipId: this.state.customerSIP.id,
      sipName: savingObjective === "Other" ? savingObjective1 : savingObjective,
      sipTarget: {
        targetType: "FixedAmount",
        targetAmountInr: multple,
      },
      milestoneName: savingObjective === "Other" ? savingObjective1 : savingObjective,
      sipInstallmentAmtInr: Number(saveApprox),
      startDate: moment().format("YYYY-MM-DD"),
      numberofInstallments: Number(period),
      frequency: frequency,
    };
    this.setState({ loader: true });
    this.props
      .updateSIP(rowData)
      .then(async (res) => {
        this.props
          .getCustomerSips(reqData)
          .then(async (res) => {
            this.setState({ loader: false });
            this.openSaveMessage();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  createSIPSubmit = () => {
    let {
      period,
      saveApprox,
      savingObjective,
      frequency,
      savingObjective1
    } = this.state;
    
    if (savingObjective.length === 0  || savingObjective === "0") {
      this.setState({ savingObjectiveerrerr: "SavingObjective can't be empty" });
      return false;
    }
    if (savingObjective1.length === 0 && savingObjective === "Other") {
      this.setState({ savingObjectiveerrerr: "Enter saving objective " });
      return false;
    }

    if (frequency === "") {
      this.setState({ frequencyerr: "Frequency can't be empty" });
      return false;
    }

    if (period === "") {
      this.setState({ perioderr: "Saving period can't be empty" });
      return false;
    }

    if (period < 2) {
      this.setState({ perioderr: "Saving period should be at minimum 2 " });
      return false;
    }

    if (saveApprox === "") {
      this.setState({ saveApproxerr: "Can't be empty" });
      return false;
    }

    if (saveApprox < 500) {
      this.setState({ saveApproxerr: "Save Approx should be at minimum 500 " });
      return false;
    }
    if (period > 365 && frequency === "daily" ) {
      this.setState({ perioderr:
        "Saving periods can't be more than 365 days " ,
        periodStateValue : true
      });
      return false;
    }
    if (period > 52 && frequency === "weekly" ) {
      this.setState({ perioderr:
        "Saving periods can't be more than 52 weeks " ,
        periodStateValue : true
      });
      return false;
    }
    if (period > 24 && frequency === "fortnightly" ) {
      this.setState({ perioderr:
        "Saving periods can't be more than 14 fortnight " ,
        periodStateValue : true
      });
      return false;
    }

    if(this.state.savingObjectiveerrerr === "" && this.state.frequencyerr === "" && this.state.perioderr === "" && this.state.saveApproxerr === "" ){
      this.handleClickOpen();
    }
      
   
  };

  getValue = () => {
    if(this.state.checkData === true){
      this.setState({inputType : "select"})
    }
    else{
      this.setState({inputType : "select"})
      if(this.state.count === 1){
        this.setState({savingObjective : ""})
        this.setState({count : this.state.count+1})
      }
     
    }
    
    
  }

  handleChange = (e) => {

  }

  render() {
    
    const { open,customerSIP,openMessage } = this.state;
    return (
      <>
        {this.state.loader ? (
          <PuffLoader color="#FFD700" size={80} css={override} />
        ) : (
          <div style={{ paddingBottom: 120 }}>
            <div className="p-t-40">
              <div className="iconSize">
                <Icon
                  size={24}
                  icon={angleLeft}
                  onClick={() => this.props.history.goBack("/goldloan")}
                />
              </div>
              <div className="page-name">Savings Planner</div>
            </div>
            <div className="box-card m-b-25">
              <div className="top_img">
                <img className="img-fluid" src={gift} alt="loan" />
              </div>
              <div className="head2">
                <h2>{customerSIP.milestoneName}</h2>
                <p>
                  Save By : {moment(customerSIP.startDate).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="wrapper-box-card">
                <div className="headLeft">
                <strong>Achieved</strong>
                  <p>Rs.{customerSIP.sipTracking.collectedAmountInr}</p>
                </div>
                <div className="headRight">
                   <strong>Target</strong>
                  <p>Rs.{customerSIP.sipTarget.targetAmountInr}</p>
                </div>
              </div>
            </div>
            <Form className="p-l-30 p-r-30">
              <FormGroup>
                <Label for="savingObjective" className="select-label">
                  Saving Objective
                </Label>
                <Input
                  type= {this.state.inputType}
                  name="savingObjective"
                  className="select-box"
                  onClick={this.getValue}
                  onChange={(e) => {
                    if (e.target.selectedOptions[0].text === "Other") {
                      this.setState({ show: true });
                    } else {
                      this.setState({ show: false });
                    }
                    this.setState({ savingObjective: e.target.value });
                    this.setState({ savingObjectiveerrerr: "" });
                  }}
                  value={this.state.savingObjective}
                >
                  <option value="0" defaultValue>
                    Select saving objective
                  </option>
                  {this.state.resData.map((item) => (
                    <option key={item._id} value={item.objectivename}>
                      {item.objectivename}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {this.state.show ? (
                <FormGroup>
                  <div className="box-row">
                    <Input
                      type="text"
                      name="savingObjective"
                      onChange={(e) => {
                        this.setState({ savingObjective1: e.target.value });
                        this.setState({ savingObjectiveerrerr: ""});
                      }}
                      placeholder="Enter saving objective"
                      className="select-input-p"
                    />
                  </div>
                </FormGroup>
              ) : null}
              {this.state.savingObjectiveerrerr ? (
                <span style={{ color: "red" }}>
                  {this.state.savingObjectiveerrerr}
                </span>
              ) : null}
              <FormGroup>
                <Label for="frequency" className="select-label">
                  Frequency
                </Label>
                <Input
                  type="select"
                  name="frequency"
                  className="select-box"
                  value={this.state.frequency}
                  onChange={(event) => {
                    this.setState({ frequency: event.target.value });
                    this.setState({ frequencyerr: "" });
                    if (event.target.value === "monthly") {
                      this.setState({ saveApprox: "" });
                      this.setState({ period: "" });
                      this.setState({ monthly: true });
                    }
                    else {
                      this.setState({saveApprox : ""});
                      this.setState({ period: "" });
                      this.setState({monthly:false});
                    }
                    if (event.target.value === "daily") {
                      this.setState({ saveApprox: "" });
                      this.setState({ period: "" });
                      this.setState({ daily: true });
                    }
                    else {
                      this.setState({saveApprox : ""});
                      this.setState({ period: "" });
                      this.setState({daily:false});
                    }
                    if (event.target.value === "weekly") {
                      this.setState({ saveApprox: "" });
                      this.setState({ period: "" });
                      this.setState({ weekly: "weekly" });
                    }
                    else {
                      this.setState({saveApprox : ""});
                      this.setState({ period: "" });
                      this.setState({weekly:false});
                    }
                    if (event.target.value === "fortnightly") {
                      this.setState({ saveApprox: "" });
                      this.setState({ period: "" });
                      this.setState({ fortnight: true });
                    }
                    else {
                      this.setState({saveApprox : ""});
                      this.setState({ period: "" });
                      this.setState({fortnight:false});
                    }
                  }}
                >
                  <option value="" disabled defaultValue>
                    Select
                  </option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="fortnightly">Fortnightly</option>
                  <option value="monthly">Monthly</option>
                </Input>
              </FormGroup>
              {this.state.frequencyerr ? (
                <span style={{ color: "red" }}>{this.state.frequencyerr}</span>
              ) : null}
              <FormGroup>
                <Label for="period" className="select-label">
                  Saving Period
                </Label>
                {this.state.monthly ? (
                  <Input
                    type="select"
                    name="period"
                    className="select-box"
                    onChange={(e) => {
                      this.setState({ period: e.target.value });
                      this.setState({ perioderr: "" });
                    }}
                    value={this.state.period}
                  >
                    <option value="" defaultValue>
                      Select saving period
                    </option>
                    <option value="3">3 Months</option>
                    <option value="6">6 Months</option>
                    <option value="9">9 Months</option>
                    <option value="12">12 Months</option>
                    <option value="15">15 Months</option>
                    <option value="18">18 Months</option>
                    <option value="21">21 Months</option>
                    <option value="24">24 Months</option>
                  </Input>
                ) :  (
                  <Input
                    // type="text"
                    name="savingperiod"
                    placeholder="Enter saving period"
                    className="select-input-p"
                    onChange={this.handleChange}
                    onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, "") ;
                      this.setState({ period: value});
                      this.setState({ perioderr: "" });
                    }}
                    value={this.state.period}
                  />
                )}
              </FormGroup>
              {this.state.perioderr ? (
                <span style={{ color: "red" }}>{this.state.perioderr}</span>
              ) : null}
              <FormGroup>
                <span className="required">*</span>
                <Label for="save" className="select-label">
                  Save Approx
                </Label>
                <div className="box-row">
                  <span className="left-top">Rs</span>
                  {this.state.weekly ? (
                    <>
                      {" "}
                      <Input
                        // type="text"
                        name="saveApprox"
                        placeholder="Save Approx amount"
                        className="select-input-btm"
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "") ;
                          this.setState({ saveApprox: value});
                          this.setState({ saveApproxerr: "" });
                        }}
                        value={this.state.saveApprox}
                      />
                      <span className="right-top">/Week</span>{" "}
                    </>
                  ) : this.state.fortnight ? (
                    <>
                      <Input
                        // type="text"
                        name="saveApprox"
                        placeholder="Save Approx amount"
                        className="select-input-btm"
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "") ;
                          this.setState({ saveApprox: value});
                          this.setState({ saveApproxerr: "" });
                        }}
                        value={this.state.saveApprox}
                      />
                      <span className="right-top">/Fortnight</span>
                    </>
                  ) : this.state.monthly ? (
                    <>
                      <Input
                        // type="text"
                        name="saveApprox"
                        placeholder="Save Approx amount"
                        className="select-input-btm"
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "") ;
                          this.setState({ saveApprox:value});
                          this.setState({ saveApproxerr: "" });
                        }}
                        value={this.state.saveApprox}
                      />
                      <span className="right-top">/Month</span>
                    </>
                  ) :  (
                    <>
                      <Input
                        // type="text"
                        name="saveApprox"
                        placeholder="Save Approx amount"
                        className="select-input-btm"
                        // value={this.state.saveApprox}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "") ;
                          this.setState({ saveApprox: value});
                          this.setState({ saveApproxerr: "" });
                        }}
                        value={this.state.saveApprox}
                      />
                      <span className="right-top">/day</span>{" "}
                    </>
                  ) }

                  {}
                </div>
                {this.state.saveApproxerr ? (
                  <span style={{ color: "red" }}>
                    {this.state.saveApproxerr}
                  </span>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label for="targetsaving" className="select-label">
                  Target saving
                </Label>
                <div className="box-row">
                  <Input
                    style={{ paddingLeft: 50 }}
                    name="targetAmountInr"
                    id="targetAmountInr"
                    type="text"
                    value={this.state.period*this.state.saveApprox}
                    className="select-input-p"
                    onChange={(e) => {
                      this.setState({ targetAmountInr: e.target.value });
                    }}
                    readOnly
                  />
                  <span className="left-top"> &nbsp; Rs</span>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="direction-row">
                  <img className="img-fluid maxWidth40" src={star} alt="loan" />
                  <Label for="content" className="comments">
                    This saving calculator is based on historical gold price and
                    the actual saved amount and will depend on the prescribing
                    gold rate at the time of purchase.
                  </Label>
                </div>
              </FormGroup>
             
            </Form>
            <Dialog open={open} >
                    <DialogContent>
                        <DialogContentText>
                            Are you sure, Change the Savings objective to {this.state.savingObjective === "Other" ? this.state.savingObjective1 : this.state.savingObjective} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}
                                color=" light" className='light'>
                            NO
                        </Button>
                        <Button
                                color=" yellow" className='yellow' autoFocus  onClick={this.CallSipApi}>
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openMessage} >
                    <DialogContent>
                        <DialogContentText>
                           Your details are updated .
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                                color=" yellow" className='yellow' autoFocus  onClick={this.message}>
                            YES
                        </Button>
                    </DialogActions>
                </Dialog>
            <div className="fixedFooter" style={{ padding: 25 }}>
              <button
                className="savingPlanCSS btn-yellow-large"
                onClick={this.createSIPSubmit}
              >
                Save
              </button>
            </div>
          </div>
        )}

      {/* <div className="footer">
                    <div className='text-center padding-30'>
                        <button className='btn-yellow-large' onClick={this.handleClickOpen("body")} >
                            Save
                        </button>
                    </div>
                </div>  */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, {
  getSavingPlan,
  createSIP,
  getCustomerSips,
  updateSIP,
})(SavingPlanDetails);
