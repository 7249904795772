/* eslint-disable */
import React, { Component } from "react";
import { Card } from "reactstrap";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import productItem from "../../images/jewellery/chain.svg";

import "../../styles/style.css";

class BuyJewellary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      totalItem:1
    };
  }

  componentDidMount = () => {
  };

  render() {
    
    return (
      <div style={{ marginTop: 50 }}>
        <div onClick={this.handleRemoveProfilePage}>
        <div className="fixedHeader"
          style={{
           zIndex: 2
          }}
        >
          <div className="iconSize" style={{ marginTop: 12 }}>
            <Icon
              size={32}
              style={{
                color: "white",
              }}
              icon={angleLeft}
              onClick={() => this.props.history.goBack("/")}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              fontFamily: "Montserrat",
              fontSize: 20,
              fontWeight: 600,
              color: "white",
              marginTop: 12,
            }}
          >
            Buy Jewellery
          </div>
        </div>
        <div>
          {this.state.loader ? (
            <div style={{ paddingTop: 60, textAlign: "center" }}>
              <Loader type="Puff" color="#FFD700" height={80} width={80} />{" "}
            </div>
          ) : (
            <div>
              <div style={{ marginBottom: 70, marginTop: 130 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Card
                          style={{
                            width: "21rem",
                            backgroundColor: "#ffffff",
                            border: "none",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.102)",
                            marginBottom: 32,
                            padding: 20,
                          }}
                        >
                          <div className="textStyle">
                            <div style={{ float: "left", fontWeight: "bold" }}>
                                <img src={productItem} className='img-fluid' alt='Gold Chain' width="30" height="30"/>
                            </div>
                              <p
                                style={{
                                  float: "left",
                                  color: "black",
                                  fontWeight: "bold",
                                  paddingLeft:10
                                }}
                              >
                               Lakshmi<br/>5gms<br/>22kt
                              </p>
                              <p
                                style={{
                                  float: "right",
                                  color: "grey",
                                  fontWeight: "bold",
                                }}
                              >
                                Show Details
                              </p>
                          </div>
                          <div className="textStyle">
                            <p style={{ float: "left" }}></p>
                            <div
                              style={{
                                fontWeight: "bold",
                                float: "right",
                                backgroundColor: "#D5B038",
                                color:"#ffffff",borderRadius:'5px'
                              }}
                            >
                             <button style={{ backgroundColor: "#D5B038",borderRadius:'5px',
                                color:"#ffffff",border:"none",paddingRight:15,paddingLeft:15}} >-</button>{this.state.totalItem}<button style={{ backgroundColor: "#D5B038",
                                color:"#ffffff",border:"none",paddingLeft:15,paddingRight:15,borderRadius:'5px'}}>+</button>
                            </div>
                          </div>
                        </Card>
                      </div>
                      </div>
                      </div>
          )}
        </div>
       </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, { })(BuyJewellary);
