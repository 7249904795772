export const TOKEN = "token" ;
export const GET_EMPLOYEE_LIST = "get_employee_list";
export const GET_BULLION_RATE = "get_bullion_rate";
export const SAVE_PERSONAL_DETAILS = "save_personal_details";
export const GET_DETAILS = "get_details";
export const GET_AMOUNT = "get_amount" ;
export const GET_CUSTOM_INFO = "get_custom_info" ;
export const GET_LOCKER_VALUE = "get_locker_value";
export const GET_ORDER_ID = "get_order_id"; 
export const SAVE_BANK_DETAILS = "save_bank_details";
export const SAVE_AGENT_DETAILS = "save_agent_details";
export const SAVE_CUSTOMER_SIP = "save_customer_sip";  
export const SAVE_JEWELLERY_DETAILS = "save_jewellery_details";  
export const SAVE_PRODUCT_ORDER = "save_product_order";  
export const PRODUCT_ID = "save_product_id";