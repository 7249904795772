import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import hallmark from "../../images/hallmark.png";
import addCard from "../../images/addCard.png";
import filter from "../../images/jewellery/filter-icon.svg";
import {
  createSIP,
  getSavingPlan,
  getCustomerSips,
  addRemoveToCart,
  getProductList,
  saveProductDetails,
  getCartItem,
} from "../../Redux/ApiCall/action";
import "../../styles/style.css";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Inventory from "./Inventory";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cart from "../../images/jewellery/cart.svg";
toast.configure();
const options = {
  useWebWorker: true,
  position: "top-center",
  autoClose: 2000,
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 100px;
`;
const ProductListing = (props) => {
  console.log("props details", props);
  const [loader, setLoader] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [productId, setProductId] = useState(
    props.history.location.state.inventorydetails.productid
  );
  const [productImages, setProductImages] = useState(
    props.location.state.imageUrl
  );
  const [purity, SetPurity] = useState("");
  const [inventoryDetail, SetInventoryDetail] = useState(
    props.location.state.inventorydetails
  );
  const [productname, setProductName] = useState(
    props.location.state.productname
  );
  const [productdecription, setProductDecription] = useState(
    props.location.state.productdescription
  );
  const [flag, setFlag] = useState(false);
  const [token, setToken] = useState("");
  const [isdisable, setIsdisable] = useState(true);
  const [resData, setResData] = useState([]);
  const [cartItem, setCartItem] = useState("");

  const chevronWidth = 40;

  const createSIPSubmit = () => {
    setLoader(true);
    props.history.push({
      pathname : '/product-listing',
      state: {flagValue : false } 
   });
  };

  

  useEffect(() => {
    handleGetItem();
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    
    let reqData = {
      jwttoken: data.jwttoken,
      bullionName: "All",
      category: "",
    };

    props
      .getProductList(reqData)
      .then(async (res) => {
        
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
    let jewelleryDetails = {
      inventoryDetail: inventoryDetail,
      productImages: productImages,
    };
    props.saveProductDetails(jewelleryDetails);
  }, []);
  const addToCart = () => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      inventoryserialnumber: inventoryDetail.inventoryserialnumber,
      productid: productId,

      imageurl: productImages,
      productname: productname,
      weightingm: inventoryDetail.weightingm,
      totalwithtaxinr: inventoryDetail.totalwithtaxinr,
      purity: inventoryDetail.convertedBullionWt.bullion.purity.displayValue,
      quantity: 1,
    };
    if (isdisable) {
      props
        .addRemoveToCart(reqData)
        .then(async (res) => {
          if (res.status === 200) {
            handleGetItem();
            setIsdisable(false);
            
            setTimeout(() => setIsdisable(true), 5000);
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleGetItem = () => {
    let { data } = props.goldLoan ? props.goldLoan.userData : "";
    let reqData = {
      jwttoken: data.jwttoken,
      bullionName: "All",
      category: "",
    };

    props
      .getCartItem(reqData)
      .then(async (res) => {
        console.log(res)
        setCartItem(res.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeFlagValue = () => {
    props.history.push({
      pathname : '/product-listing',
      state: {flagValue : false } 
   });
  }

  return (
    <>
      {loader ? (
        <PuffLoader color="#FFD700" size={80} css={override} />
      ) : (
        <div>
          <div
            className="p-t-20"
            style={{ backgroundColor: "#FFF5D4", paddingBottom: 20 }}
          >
            <div style={{ zIndex: 1000, bottom: "120px" }} className="cart" onClick={()=>{
              props.history.push("/cart")
            }}>
              <p style={{ marginTop: 4, fontWeight: 200, fontSize: 13 }}>{cartItem.length}</p>
              <img src={cart} alt="Cart" />
            </div>
            <div className="iconSize">
              <Icon
                size={24}
                icon={angleLeft}
                onClick={changeFlagValue}
              />
            </div>
            <div className="page-name">Product Details </div>
          </div>

          <Form style={{ padding: 20 }}>
            <div style={{ height: "8rem", border: "none" }}>
              <div>
                <img
                  style={{
                    marginLeft: "80px",
                  }}
                  src={productImages}
                  alt="Gold Chain"
                  width="170px"
                  height="130px"
                />
              </div>
            </div>
            <div className="card-jewellery-box">
              <div className="card-jewellery-three-2">
                <p>{inventoryDetail.weightingm}</p>
                <h5 className="price-item1">gram</h5>
              </div>
              <div className="card-jewellery-three-2">
                <p>
                  {
                    inventoryDetail.convertedBullionWt.bullion.purity
                      .displayValue
                  }
                </p>
                <h5 className="price-item1">
                  {inventoryDetail.convertedBullionWt.bullion.purity.value}
                </h5>
              </div>
              <div className="card-jewellery-three-3">
                <p>₹{parseInt(inventoryDetail.totalwithtaxinr)}</p>
                <h5 className="price-item1">Include making charges</h5>
              </div>
            </div>

            <div>
              <div style={{ float: "left" }}>
                <div>
                  <p style={{ fontSize: 14 }}>
                    <strong>{productname}</strong>
                  </p>
                  <p style={{ fontSize: 10 }}>{productdecription ? productdecription : ''}</p>
                </div>
                {productdecription ? 
                <div>
                  <p style={{ fontSize: 14 }}>
                    <strong>In Your Cart -</strong>
                  </p>
                  <p style={{ fontSize: 10 }}>{productdecription}</p>
                </div> : '' }
              </div>
              <div style={{ float: "right", textAlign: "center" }}>
                <div>
                  <p style={{ fontSize: 10 }}>
                    <strong>Hallmarked</strong>
                  </p>
                  <img src={hallmark} alt="Hall Mark" width="120" height="71" />
                </div>
              </div>
            </div>
            {/* <div onClick={addToCart} className="card-jewellery-items-5">
              <p>ADD TO CART</p>
            </div> */}
            {/* <div
              onClick={() => {
                props.history.push("/cart");
              }}
              className="card-jewellery-items-1"
            >
              <img
                className="img-fluid"
                src={addCard}
                height="20"
                width="20"
                alt="Cart"
              />
            </div> */}
            {resData.map((item, index) => {
              return index < 1 ? (
                <div style={{ float: "left", paddingBottom: 5 }}>
                  <div className="card-jewellery-box">
                    <div className="card-jewellery-three">
                      {item.images[0] ? (
                        <div className="img-box">
                          <img
                            src={item.images[0].url}
                            className="img-fluid"
                            alt="Gold Chain"
                            height="5"
                          />
                        </div>
                      ) : (
                        <div className="img-box">
                          <img
                            src="https://test-partnernapi-1.dvarasmartgold.in/publicApi/productImage/47be5034-b3c3-41b1-9e09-d87acfc33c21.jpg"
                            className="img-fluid"
                            alt="Gold Chain"
                          />
                        </div>
                      )}

                      <p>Gold Chain</p>
                      <h5 className="price-item">
                        ₹{inventoryDetail.totalwithtaxinr}
                      </h5>
                    </div>
                    {/* <div className="card-jewellery-three">
                    <div className="img-box">
                      <img
                        src={productItem}
                        className="img-fluid"
                        alt="Gold Chain"
                      />
                    </div>
                    <p>Gold Chain</p>
                    <h5 className="price-item">₹6,725</h5>
                  </div> */}
                    {/* <div className="card-jewellery-three">
                    <div className="img-box">
                      <img
                        src={productItem}
                        className="img-fluid"
                        alt="Gold Chain"
                      />
                    </div>
                    <p>Gold Chain</p>
                    <h5 className="price-item">₹6,725</h5>
                  </div> */}
                  </div>
                </div>
              ) : (
                ""
              );
            })}

            <div style={{ float: "right" }}></div>

            <br />
            {/* <div onClick={handleInventory}> */}
            <div>
              {/* <div className="card-jewellery-items">
                <p>SELECT MORE</p>
              </div> */}
               <div onClick={addToCart} className="card-jewellery-items">
              <p>ADD TO CART</p>
            </div>
              <div className="card-jewellery-items">
                <p
                  onClick={() =>
                    props.history.push("/delivery", {
                      inventoryDetail: inventoryDetail,
                      imageUrl: productImages,
                      productname: productname,
                      cartItemNumber:cartItem.length
                    })
                  }
                >
                  CHECKOUT
                </p>
              </div>
            </div>
          </Form>

          <div className="fixedFooter">
            {/* <div className="filter-icon">
              <img src={filter} alt="Filter" />
            </div> */}
            <div className="btnCenter">
              <button
                className="allCategoryJewellary"
                onClick={() => createSIPSubmit()}
              >
                {/* View All Categories */}
                Select More
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    goldLoan: state.goldLoan,
  };
};

export default connect(mapStateToProps, {
  createSIP,
  getSavingPlan,
  getCustomerSips,
  addRemoveToCart,
  getProductList,
  saveProductDetails,
  getCartItem,
})(ProductListing);
